@font-face {
  font-family: 'MontserratLight';
  src: local('MontserratLight'), url(../fonts/Montserrat/Montserrat-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'),
    url(../fonts/Montserrat/Montserrat-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('MontserratSemiBold'),
    url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url(../fonts/Montserrat/Montserrat-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProLight';
  src: local('SourceSansProLight'),
    url(../fonts/SourceSansPro/SourceSansPro-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProRegular';
  src: local('SourceSansProRegular'),
    url(../fonts/SourceSansPro/SourceSansPro-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProSemiBold';
  src: local('SourceSansProSemiBold'),
    url(../fonts/SourceSansPro/SourceSansPro-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProBold';
  src: local('SourceSansProBold'),
    url(../fonts/SourceSansPro/SourceSansPro-Bold.ttf) format('opentype');
}
