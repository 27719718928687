$fontFamily1: 'SourceSansProRegular';
$fontFamily1Light: 'SourceSansProLight';
$fontFamily1SemiBold: 'SourceSansProSemiBold';
$fontFamily1Bold: 'SourceSansProBold';

$fontFamily2: 'MontserratRegular';
$fontFamily2Light: 'MontserratLight';
$fontFamily2SemiBold: 'MontserratSemiBold';
$fontFamily2Bold: 'MontserratBold';

$colorTundora: #4e4e4e;
$colorDarkGray: #303030;
$colorDivider: #e8e8e8;
$colorAmericanSilver: #cecece;
$colorBoxShadow: #6c757d80;
$transparent: transparent;

$colorWhite: #ffffff;
$colorAlabaster: #f9f9f9;
$colorAlto: #d9d9d9;
$colorStoneWhite: #ffffffc2;

$colorBiscay: #19356d;
$colorForestGreen: #3da52b;
$colorCorn: #dcae04;
$colorRed: #fd0c0c;
$colorOrange: #ef4328;

$colorBlueFlag: #6d97ec;
$colorRedFlag: #d81010;
$colorGreenFlag: #9184e1;
$colorOrangeFlag: #82398d;

$colorBlack: #000;
$firstBlackColor: #0000001a;
$secondBlackColor: #00000033;
$thirdBlackColor: #0000004d;
$fourthBlackColor: #00000066;
$fifthBlackColor: #00000080;
$sixthBlackColor: #00000099;
$seventhBlackColor: #000000b3;
$eighthBlackColor: #000000cc;
$ninthBlackColor: #000000e6;

$colorGigas: #4e3f92;
$colorPortGore: #29295e;
$colorVividViolet: #82398d;
$colorJagger: #480e51;
$colorMineShaft: #303030;
$colorCornFlowerBlue: #e5dffb;
$colorSapphire: #314b99;
$colorPortage: #2e245d;

$firstColor: $colorGigas;
$secondColor: $colorTundora;
$thirdColor: $colorWhite;
$fourthColor: $colorPortGore;
$fifthColor: $colorVividViolet;
$sixthColor: $colorJagger;
$seventhColor: $colorCornFlowerBlue;
$eighthColor: $colorAlabaster;
$ninthColor: $colorAlto;
$tenthColor: $colorBiscay;
$eleventhColor: $colorSapphire;
$twelfthColor: $colorForestGreen;
$thirteenthColor: $colorRed;
$fourteenthColor: $colorPortage;
$fifteenthColor: $colorCorn;
$sixteenthColor: $colorDarkGray;
$seventeenthColor: $colorStoneWhite;
$eighteenthColor: $colorDivider;
$nineteenthColor: $colorMineShaft;

$firstButtonColorBackground: $firstColor;
$firstButtonColorBorder: $firstColor;
$firstButtonColorText: $thirdColor;

$firstButtonInvertedColorBackground: $thirdColor;
$firstButtonInvertedColorBorder: $firstColor;
$firstButtonInvertedColorText: $firstColor;

//1. Main Page
$colorMainPageContentDescTitle: $secondColor;
$colorMainPageContentDescSubTitle: $secondColor;

$colorMainPageDescriptionTitle: $secondColor;
$colorMainPageDescription: $secondColor;
$colorMainPageIcons: $secondColor;

$colorMainPageContentPartTitle: $secondColor;
$colorMainPageContentPartSubTitle: $colorAmericanSilver;

$colorMainPageProjectDetailTitle: $secondColor;
$colorMainPageEmptyProjectText: $secondColor;
$colorMainPageMainWrapperBackground: $eighthColor;
$colorMainPageSecondaryWrapperBackground: $thirdColor;

$colorMainPageShowAllButtonBackground: $firstButtonColorBackground;
$colorMainPageShowAllButtonBorder: $firstButtonColorBorder;
$colorMainPageShowAllButtonText: $thirdColor;
$colorMainPageHoverShowAllButtonBackground: $firstButtonColorBackground;
$colorMainPageHoverShowAllButtonBorder: $firstButtonColorBorder;
$colorMainPageHoverShowAllButtonText: $thirdColor;

////1.1 Main Page Slider
$colorSliderFirstTitleText: $thirdColor;
$colorSliderFirstText: $thirdColor;

$colorSliderFirstButtonBackground: $sixthColor;
$colorSliderFirstButtonBorder: $sixthColor;
$colorSliderFirstButtonText: $thirdColor;
$colorSliderHoverFirstButtonBackground: $sixthColor;
$colorSliderHoverFirstButtonBorder: $sixthColor;
$colorSliderHoverFirstButtonText: $thirdColor;

$colorSliderFirstDotBackground: $sixthColor;
$colorSliderActiveFirstDotBackground: $sixthColor;

$colorSliderSecondTitleText: $thirdColor;
$colorSliderSecondText: $thirdColor;

$colorSliderSecondButtonBackground: $sixthColor;
$colorSliderSecondButtonBorder: $sixthColor;
$colorSliderSecondButtonText: $thirdColor;
$colorSliderHoverSecondButtonBackground: $sixthColor;
$colorSliderHoverSecondButtonBorder: $sixthColor;
$colorSliderHoverSecondButtonText: $thirdColor;

$colorSliderSecondDotBackground: $thirdColor;
$colorSliderSecondActiveDotBackground: $thirdColor;

//2. Dashboard
////2.1 Sidebar
$colorDashboardSidebarMenuFromBackground: $fourthColor;
$colorDashboardSidebarMenuToBackground: $fourthColor;
$colorDashboardSidebarDivider: $thirdColor;

$colorDashboardSidebarIconBoxShadow: $seventeenthColor;
$colorDashboardSidebarIcon: $thirdColor;
$colorDashboardSidebarText: $seventeenthColor;

$colorDashboardSidebarActiveIcon: $seventeenthColor;
$colorDashboardSidebarActiveText: $thirdColor;

$colorDashboardSidebarActiveItemFromBackground: $seventeenthColor;
$colorDashboardSidebarActiveItemToBackground: $transparent;

$colorDashboardSidebarMobileButtonBackground: $thirdColor;
$colorDashboardSidebarMobileButtonBorder: $ninthColor;
$colorDashboardSidebarMobileButtonText: $sixthBlackColor;
$colorDashboardSidebarMobileHoverButtonBackground: $thirdColor;
$colorDashboardSidebarMobileHoverButtonBorder: $sixthColor;
$colorDashboardSidebarMobileHoverButtonText: $sixthColor;

////2.2 Welcome
$colorDashboardWelcomeTitle: $firstColor;
$colorDashboardWelcomeDescription: $firstColor;
$colorDashboardWelcomeDivider: $firstColor;

////2.3 Profile
//////2.3.1 Profile Info
$colorDashboardProfileInfoContainerBoxShadow: $secondBlackColor;
$colorDashboardProfileInfoContainerBackground: linear-gradient(180deg, #3e4598 0%, #0f5b9d 100%);
$colorDashboardProfileInfoContainerTitle: $thirdColor;
$colorDashboardProfileInfoContainerText: $thirdColor;

$colorDashboardProfileInfoDisabledInputBackground: $thirdColor;
$colorDashboardProfileInfoDisabledInputText: $thirdColor;

$colorDashboardProfileUpdateButtonBackground: $firstButtonColorBackground;
$colorDashboardProfileUpdateButtonBorder: $firstButtonColorBorder;
$colorDashboardProfileUpdateButtonText: $firstButtonColorText;
$colorDashboardProfileUpdateHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardProfileUpdateHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardProfileUpdateHoverButtonText: $firstButtonColorText;

$colorDashboardProfileInfoButtonBackground: $fifthColor;
$colorDashboardProfileInfoButtonBorder: $fifthColor;
$colorDashboardProfileInfoButtonText: $thirdColor;
$colorDashboardProfileInfoHoverButtonBackground: $fifthColor;
$colorDashboardProfileInfoHoverButtonBorder: $fifthColor;
$colorDashboardProfileInfoHoverButtonText: $thirdColor;

//////2.3.2 Identity
$colorDashboardIdentityVerifiedContainerBackground: $eleventhColor;
$colorDashboardIdentityVerifiedContainerBorder: $eleventhColor;

$colorDashboardIdentityPendingContainerBackground: $eleventhColor;
$colorDashboardIdentityPendingContainerBorder: $eleventhColor;

$colorDashboardIdentityVerifyContainerBackground: $eleventhColor;
$colorDashboardIdentityVerifyContainerBorder: $eleventhColor;

$colorDashboardIdentityContainerBoxShadow: $secondBlackColor;
$colorDashboardIdentityHeader: $thirdColor;
$colorDashboardIdentityTitle: $thirdColor;
$colorDashboardIdentityInputText: $seventhBlackColor;

$colorDashboardIdentityBorder: $thirdColor;
$colorDashboardIdentityLabel: $thirdColor;
$colorDashboardIdentityText: $thirdColor;
$colorDashboardIdentityVerificationErrorText: $thirteenthColor;

$colorDashboardIdentityButtonBackground: $firstButtonColorBackground;
$colorDashboardIdentityButtonBorder: $firstButtonColorBorder;
$colorDashboardIdentityButtonText: $firstButtonColorText;
$colorDashboardIdentityHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardIdentityHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardIdentityHoverButtonText: $firstButtonColorText;

$colorDashboardIdentityVerificationText: $fifthColor;

//////2.3.3 Investment
$colorDashboardInvestmentContainerBoxShadow: $secondBlackColor;
$colorDashboardInvestmentContainerBackground: $seventhColor;

$colorDashboardInvestmentContainerTitle: $tenthColor;
$colorDashboardInvestmentContainerText: $tenthColor;
$colorDashboardInvestmentContainerLink: $fifthColor;
$colorDashboardInvestmentContainerHoverLink: $fifthColor;

$colorDashboardInvestmentContainerDivider: $colorBlack;

$colorDashboardInvestmentContainerButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentContainerButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentContainerButtonText: $firstButtonColorText;
$colorDashboardInvestmentContainerHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentContainerHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentContainerHoverButtonText: $firstButtonColorText;

////////2.3.3.1 Income Statement
$colorDashboardInvestmentIncomeStatementHeader: $nineteenthColor;
$colorDashboardInvestmentIncomeStatementTitle: $nineteenthColor;

$colorDashboardInvestmentIncomeStatementGridBackground: $eighthColor;
$colorDashboardInvestmentIncomeStatementTableBorder: $firstColor;
$colorDashboardInvestmentIncomeStatementTableHeader: $secondColor;
$colorDashboardInvestmentIncomeStatementTableData: $secondColor;

$colorDashboardInvestmentIncomeStatementButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentIncomeStatementButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentIncomeStatementButtonText: $firstButtonColorText;
$colorDashboardInvestmentIncomeStatementHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentIncomeStatementHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentIncomeStatementHoverButtonText: $firstButtonColorText;

//////2.3.4 ChangePassword
$colorDashboardChangePasswordContainerBoxShadow: $secondBlackColor;
$colorDashboardChangePasswordContainerBackground: $seventhColor;
$colorDashboardChangePasswordTitle: $tenthColor;
$colorDashboardChangePasswordText: $tenthColor;

$colorDashboardChangePasswordButtonBackground: $firstButtonColorBackground;
$colorDashboardChangePasswordButtonBorder: $firstButtonColorBorder;
$colorDashboardChangePasswordButtonText: $thirdColor;
$colorDashboardChangePasswordHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardChangePasswordHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardChangePasswordHoverButtonText: $thirdColor;

//////2.3.5 E-mail Subscription
$colorDashboardEmailSubscriptionBoxShadow: $secondBlackColor;
$colorDashboardEmailSubscriptionBackground: $thirdColor;
$colorDashboardEmailSubscriptionTitle: $fifthColor;
$colorDashboardEmailSubscriptionText: $sixteenthColor;

$colorDashboardEmailSubscriptionButtonBackground: $firstButtonColorBackground;
$colorDashboardEmailSubscriptionButtonBorder: $firstButtonColorBorder;
$colorDashboardEmailSubscriptionButtonText: $firstButtonColorText;
$colorDashboardEmailSubscriptionHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardEmailSubscriptionHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardEmailSubscriptionHoverButtonText: $firstButtonColorText;

//////2.3.6 Legal Info
$colorDashboardLegalInfoContainerBoxShadow: $secondBlackColor;
$colorDashboardLegalInfoContainerBackground: $seventhColor;
$colorDashboardLegalInfoContainerText: $firstColor;
$colorDashboardLegalInfoContainerLink: $firstColor;
$colorDashboardLegalInfoContainerList: $firstColor;

//////2.3.7 Delete User
$colorDashboardDeleteUserContainerBoxShadow: $secondBlackColor;
$colorDashboardDeleteUserContainerBackground: $seventhColor;
$colorDashboardDeleteUserText: $firstColor;
$colorDashboardDeleteUserError: $firstColor;

$colorDashboardDeleteUserButtonBackground: $firstButtonColorBackground;
$colorDashboardDeleteUserButtonBorder: $firstButtonColorBorder;
$colorDashboardDeleteUserButtonText: $firstButtonColorText;
$colorDashboardDeleteUserHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardDeleteUserHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardDeleteUserHoverButtonText: $firstButtonColorText;

//////2.3.8 Support Info
$colorDashboardSupportInfoContainerBoxShadow: $secondBlackColor;
$colorDashboardSupportInfoContainerBackground: $seventhColor;
$colorDashboardSupportInfoText: $firstColor;
$colorDashboardSupportInfoLink: $firstColor;

////2.4 Projects
$colorDashboardProjectsHeader: $sixteenthColor;
$colorDashboardProjectsTitle: $secondColor;
$colorDashboardBoxShadow: $fourthBlackColor;

$colorDashboardProjectsHeaderText: $secondColor;
$colorDashboardProjectsDescriptionText: $secondColor;
$colorDashboardProjectsDescriptionTitle: $secondColor;

$colorDashboardProjectsSucceededText: $twelfthColor;
$colorDashboardProjectsFailedText: $thirteenthColor;
$colorDashboardProjectsProcessingText: $fifteenthColor;

$colorDashboardProjectsRowBackground: $eighthColor;
$colorDashboardProjectsColBackground: $thirdColor;

$colorDashboardProjectsLogoContainerBackground: $firstBlackColor;
$colorDashboardProjectsLogoContainerBackgroundHover: $seventhBlackColor;

$colorDashboardProjectsLogoContainerInspectButtonBorder: $firstColor;
$colorDashboardProjectsLogoContainerInspectButtonBackground: $firstColor;
$colorDashboardProjectsLogoContainerInspectButtonText: $thirdColor;

$colorDashboardProjectsLogoContainerUpdateButtonBorder: $firstColor;
$colorDashboardProjectsLogoContainerUpdateButtonBackground: $firstColor;
$colorDashboardProjectsLogoContainerUpdateButtonText: $thirdColor;

$colorDashboardProjectsLogoContainerTablesButtonBorder: $firstColor;
$colorDashboardProjectsLogoContainerTablesButtonBackground: $thirdColor;
$colorDashboardProjectsLogoContainerTablesButtonText: $firstColor;

//////2.4.1 Change Request
$colorDashboardProjectsChangeRequestTitle: $firstColor;
$colorDashboardProjectsChangeRequestDescription: $eighthBlackColor;

$colorDashboardProjectsChangeRequestButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsChangeRequestButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsChangeRequestButtonText: $firstButtonColorText;
$colorDashboardProjectsChangeRequestHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsChangeRequestHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsChangeRequestHoverButtonText: $firstButtonColorText;

//////2.4.2 Tables
$colorDashboardProjectsTablesContainerBackground: $eighthColor;
$colorDashboardProjectsTablesTitle: $fifthColor;
$colorDashboardProjectsTablesText: $firstColor;
$colorDashboardProjectsTablesSummaryText: $nineteenthColor;

$colorDashboardProjectsTablesDivider: $nineteenthColor;
$colorDashboardProjectsTablesGridBackground: $eighthColor;

//////2.4.3 Updates
$colorDashboardProjectsUpdatesHeader: $firstColor;
$colorDashboardProjectsUpdatesLabel: $secondColor;
$colorDashboardProjectsUpdatesText: $secondColor;

$colorDashboardProjectsUpdateTimelineTimeText: $secondColor;
$colorDashboardProjectsUpdateTimelineDivider: $secondColor;
$colorDashboardProjectsUpdateTimelineText: $secondColor;
$colorDashboardProjectsUpdateManagementText: $secondColor;

$colorDashboardProjectsUpdatesButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsUpdatesButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsUpdatesButtonText: $firstButtonColorText;
$colorDashboardProjectsUpdatesHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsUpdatesHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsUpdatesHoverButtonText: $firstButtonColorText;

////2.5 Favourites
$colorDashboardFavoutitesHeader: $sixteenthColor;

$colorDashboardFavouritesTableBackground: $eighthColor;
$colorDashboardFavouritesTableData: $sixteenthColor;
$colorDashboardFavouritesTableHeader: $secondColor;
$colorDashboardFavouritesTableLink: $firstColor;
$colorDashboardFavoutitesIcon: $thirteenthColor;

////2.6 Messages
$colorDashboardMessagesHeaderText: $secondColor;
$colorDashboardMessagesItemText: $sixteenthColor;
$colorDashboardMessagesItemBorder: $firstColor;

$colorDashboardMessagesTabText: $sixteenthColor;
$colorDashboardMessagesTabHover: $firstColor;
$colorDashboardMessagesActiveTabText: $firstColor;
$colorDashboardMessagesActiveTabBorder: $firstColor;

$colorDashboardMessagesButtonBorder: $ninthColor;
$colorDashboardMessagesButtonBackground: $thirdColor;
$colorDashboardMessagesButtonText: $secondColor;
$colorDashboardMessagesHoverButtonBorder: $sixthColor;
$colorDashboardMessagesHoverButtonBackground: $thirdColor;
$colorDashboardMessagesHoverButtonText: $sixthColor;

//////2.6.1 Direct Message
$colorDashboardMessagesDirectMessageHeaderText: $sixteenthColor;
$colorDashboardMessagesDirectMessageHeaderIcon: $sixteenthColor;
$colorDashboardMessagesDirectMessageSenderText: $sixteenthColor;

$colorDashboardMessagesDirectMessageSentBackground: $secondColor;
$colorDashboardMessagesDirectMessageReceivedBackground: $firstColor;
$colorDashboardMessagesDirectMessageCardText: $thirdColor;

$colorDashboardMessagesDirectMessageButtonBackground: $firstButtonColorBackground;
$colorDashboardMessagesDirectMessageButtonBorder: $firstButtonColorBorder;
$colorDashboardMessagesDirectMessageButtonText: $firstButtonColorText;
$colorDashboardMessagesDirectMessageHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardMessagesDirectMessageHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardMessagesDirectMessageHoverButtonText: $firstButtonColorText;

////2.7 Portfolio
$colorDashboardPortfolioHeader: $sixteenthColor;
$colorDashboardPortfolioTitle: $fifthColor;
$colorDashboardPortfolioProjectName: $secondColor;

$colorDashboardPortfolioTableBackground: $eighthColor;
$colorDashboardPortfolioTableHeader: $secondColor;
$colorDashboardPortfolioTableData: $secondColor;

$colorDashboardPortfolioDisableButtonBackground: $ninthColor;
$colorDashboardPortfolioDisableButtonBorder: $ninthColor;
$colorDashboardPortfolioDisableButtonText: $thirdColor;
$colorDashboardPortfolioDisableHoverButtonBackground: $ninthColor;
$colorDashboardPortfolioDisableHoverButtonBorder: $ninthColor;
$colorDashboardPortfolioDisableHoverButtonText: $thirdColor;

//////2.7.1 Investment Summary
$colorDashboardPortfolioCount: $fifthColor;
$colorDashboardPortfolioAmount: $fifthColor;
$colorDashboardPortfolioCountText: $secondColor;
$colorDashboardPortfolioAmountText: $secondColor;

$colorDashboardPortfolioFilterText: $secondColor;

$colorDashboardPortfolioIconBorder: $eighthColor;
$colorDashboardPortfolioIconBackground: $transparent;
$colorDashboardPortfolioIcon: $fifthColor;
$colorDashboardPortfolioFocusIconBorder: $eighthColor;
$colorDashboardPortfolioFocusIconBackground: $transparent;
$colorDashboardPortfolioFocusIcon: $fifthColor;

//////2.7.2 Ongoing Projects
$colorDashboardPortfolioCancelButtonBorder: $transparent;
$colorDashboardPortfolioCancelButtonBackground: $thirteenthColor;
$colorDashboardPortfolioCancelButtonText: $thirdColor;
$colorDashboardPortfolioHoverCancelButtonBorder: $transparent;
$colorDashboardPortfolioHoverCancelButtonBackground: $thirteenthColor;
$colorDashboardPortfolioHoverCancelButtonText: $thirdColor;

//////2.7.3 Fineshed Projects
$colorDashboardPortfolioInspectButtonBorder: $transparent;
$colorDashboardPortfolioInspectButtonBackground: $firstColor;
$colorDashboardPortfolioInspectButtonText: $thirdColor;
$colorDashboardPortfolioHoverInspectButtonBorder: $transparent;
$colorDashboardPortfolioHoverInspectButtonBackground: $firstColor;
$colorDashboardPortfolioHoverInspectButtonText: $thirdColor;

//3. Projects
////3.1 Projects Sidebar
$colorProjectsSidebarHeaderText: $sixthColor;
$colorProjectsSidebarTitleText: $sixthColor;
$colorProjectsSidebarCleanFilterText: $fifthColor;
$colorProjectsSidebarText: $secondColor;

$colorProjectsSidebarOrderArrow: $secondColor;

$colorProjectsSidebarMobileButtonBackground: $thirdColor;
$colorProjectsSidebarMobileButtonBorder: $ninthColor;
$colorProjectsSidebarMobileButtonText: $sixthBlackColor;
$colorProjectsSidebarMobileHoverButtonBackground: $thirdColor;
$colorProjectsSidebarMobileHoverButtonBorder: $sixthColor;
$colorProjectsSidebarMobileHoverButtonText: $sixthColor;

////3.2 Project Card
$colorFlagRemainingTime: $thirdColor;

$colorAddFavoriteTextBorder: $thirdColor;
$colorAddFavoriteTextBackground: $thirdColor;
$colorAddFavoriteText: $fifthColor;

$colorRemoveFavoriteTextBorder: $fifthColor;
$colorRemoveFavoriteTextBackground: $fifthColor;
$colorRemoveFavoriteText: $thirdColor;

$colorCardText: $secondColor;
$colorCardCategoryBackground: $thirdColor;
$colorCardCategory: $fifthColor;

$colorCardSuccessText: $twelfthColor;
$colorCardFailText: $thirteenthColor;

////3.3 Project Detail
$colorProjectDetailHeader: $secondColor;
$colorProjectDetailCategoryText: $fourthColor;
$colorProjectDetailLocationText: $secondColor;
$colorProjectDetailHeaderSummary: $secondColor;

$colorProjectDetailTabBackground: $firstColor;
$colorProjectDetailTabActiveBackground: $firstColor;
$colorProjectDetailTabText: $thirdColor;
$colorProjectDetailTabActiveTabText: $thirdColor;

$colorProjectDetailTabBorder: $firstColor;
$colorProjectDetailTabMobileArrow: $thirdColor;
$colorProjectDetailTabMobileArrowBackground: $firstColor;

$colorProjectDetailWebSiteUrl: $fourthColor;
$colorProjectDetailHashtag: $secondColor;
$colorProjectDetailLabels: $secondColor;
$colorProjectDetailProjectDivider: $eighteenthColor;
$colorProjectDetailAbout: $secondColor;

//////3.3.1 Project Detail Card
$colorProjectDetailCardTitle: $secondColor;
$colorProjectDetailCardText: $secondColor;
$colorProjectDetailCardBackground: $thirdColor;
$colorProjectDetailCardShareText: $secondColor;

$colorProjectDetailCardFavored: $firstColor;
$colorProjectDetailCardUnfavored: $secondColor;

$colorProjectDetailFinishedButtonBackground: $eighthColor;
$colorProjectDetailFinishedButtonText: $twelfthColor;

$colorProjectDetailFailButtonBackground: $eighthColor;
$colorProjectDetailFailButtonText: $thirteenthColor;

$colorProjectDetailDraftButtonBackground: $seventhColor;
$colorProjectDetailDraftButtonText: $thirdColor;

$colorProjectDetailButtonBackground: $firstColor;
$colorProjectDetailButtonText: $thirdColor;

//////3.3.2 Project Details
$colorProjectDetailProjectOwner: $secondColor;
$colorProjectDetailIcon: $secondColor;
$colorProjectDetailSiteUrl: $fourthColor;

$colorProjectDetailTitle: $secondColor;
$colorProjectDetailText: $secondColor;
$colorProjectDetailBoldText: $secondColor;
$colorProjectDetailDivider: $eighteenthColor;

$colorProjectDetailTeamCardBackground: $thirdColor;
$colorProjectDetailTeamCardBorder: $thirdColor;
$colorProjectDetailTeamCardHeader: $secondColor;
$colorProjectDetailTeamCardTitle: $secondColor;
$colorProjectDetailTeamCardText: $secondColor;
$colorProjectDetailTeamCardIcon: $secondColor;

$colorProjectDetailSendMessageButtonBackground: $thirdColor;
$colorProjectDetailSendMessageButtonBorder: $ninthColor;
$colorProjectDetailSendMessageButtonText: $sixthBlackColor;
$colorProjectDetailSendMessageHoverButtonBackground: $thirdColor;
$colorProjectDetailSendMessageHoverButtonBorder: $fourthColor;
$colorProjectDetailSendMessageHoverButtonText: $fourthColor;

//////3.3.3 Project Documents
$colorProjectDetailDocumentTitleText: $secondColor;
$colorProjectDetailDocumentsIcon: $secondColor;
$colorProjectDetailDocumentsIconText: $fifthColor;

//////3.3.4 Project Faq
$colorProjectDetailFaqHeaderBackground: $eighthColor;
$colorProjectDetailFaqHeaderText: $secondColor;
$colorProjectDetailFaqHeaderBorder: $ninthColor;

$colorProjectDetailFaqContentBackground: $eighthColor;
$colorProjectDetailFaqContentText: $secondColor;

//////3.3.5 Project Updates
$colorProjectDetailUpdateText: $secondColor;
$colorProjectDetailUpdateDivider: $fifthColor;

$colorProjectDetailUpdateTimelineTimeText: $secondColor;
$colorProjectDetailUpdateTimelineText: $secondColor;
$colorProjectDetailUpdateTimelineDivider: $secondColor;

//////3.3.6 Project Comment
$colorProjectDetailCommentNoDiscussion: $firstColor;

$colorProjectDetailCommentExplain: $thirteenthColor;
$colorProjectDetailCommentDivider: $eighteenthColor;

$colorProjectDetailCommentCommenter: $secondColor;
$colorProjectDetailCommentTime: $secondColor;
$colorProjectDetailCommentComment: $secondColor;
$colorProjectDetailCommentCount: $sixthColor;

$colorProjectDetailCommentButtonBackground: $firstButtonColorBackground;
$colorProjectDetailCommentButtonBorder: $firstButtonColorBorder;
$colorProjectDetailCommentButtonText: $firstButtonColorText;
$colorProjectDetailCommentHoverButtonBackground: $firstButtonColorBackground;
$colorProjectDetailCommentHoverButtonBorder: $firstButtonColorBorder;
$colorProjectDetailCommentHoverButtonText: $firstButtonColorText;

$colorProjectDetailCommentAnswerButtonBackground: $thirdColor;
$colorProjectDetailCommentAnswerButtonBorder: $fourthColor;
$colorProjectDetailCommentAnswerButtonText: $fourthColor;
$colorProjectDetailCommentAnswerHoverButtonBackground: $thirdColor;
$colorProjectDetailCommentAnswerHoverButtonBorder: $fourthColor;
$colorProjectDetailCommentAnswerHoverButtonText: $fourthColor;

//////3.3.7 Project Images
$colorProjectDetailImagesLink: $sixthColor;

////3.4 Project Create
$colorProjectCreateFormBackground: $eighthColor;
$colorProjectCreateFormReadonlyBackground: $thirdColor;

$colorProjectCreateFormHeader: $secondColor;
$colorProjectCreateFormTitle: $secondColor;
$colorProjectCreateFormLabel: $secondColor;
$colorProjectCreateFormText: $secondColor;
$colorProjectCreateLink: $firstColor;

$colorProjectCreateTextAreaBorder: $ninthColor;
$colorProjectCreateTextAreaText: $eighthBlackColor;

$colorProjectCreateImagesLink: $sixthColor;
$colorProjectCreateImagesTrashIcon: $thirteenthColor;

$colorUploaderButtonBackground: $firstColor;
$colorUploaderButtonBorder: $firstColor;
$colorUploaderButtonText: $firstButtonColorText;
$colorUploaderHoverButtonBackground: $firstColor;
$colorUploaderHoverButtonBorder: $firstColor;
$colorUploaderHoverButtonText: $firstButtonColorText;

$colorProjectCreateEditButtonBackground: $transparent;
$colorProjectCreateEditButtonBorder: $transparent;
$colorProjectCreateEditButtonText: $sixthBlackColor;
$colorProjectCreateHoverEditButtonBackground: $transparent;
$colorProjectCreateHoverEditButtonBorder: $transparent;
$colorProjectCreateHoverEditButtonText: $sixthColor;

$colorProjectCreateButtonBackground: $firstButtonColorBackground;
$colorProjectCreateButtonBorder: $firstButtonColorBorder;
$colorProjectCreateButtonText: $thirdColor;
$colorProjectCreateHoverButtonBackground: $firstButtonColorBackground;
$colorProjectCreateHoverButtonBorder: $firstButtonColorBorder;
$colorProjectCreateHoverButtonText: $thirdColor;

$colorProjectCreateDisableAddButtonBackground: $firstButtonColorBackground;
$colorProjectCreateDisableAddButtonBorder: $firstButtonColorBorder;
$colorProjectCreateDisableAddButtonText: $thirdColor;

$colorProjectCreateAddButtonBackground: $firstButtonColorBackground;
$colorProjectCreateAddButtonBorder: $firstButtonColorBorder;
$colorProjectCreateAddButtonText: $firstButtonColorText;

$colorProjectCreateSubmitButtonBackground: $fourteenthColor;

//4. Login
$colorLoginBackground: $thirdColor;
$colorLoginCardBackground: $thirdColor;

$colorLoginHeader: $secondColor;
$colorLoginSubHeader: $secondColor;

$colorLoginText: $secondColor;
$colorLoginLink: $firstColor;

$colorLoginForgetPassword: $firstColor;
$colorLoginSignupCheckLabel: $secondColor;
$colorLoginSignupLink: $fifthColor;

$colorLoginButtonBackground: $firstColor;
$colorLoginButtonBorder: $firstColor;
$colorLoginButtonText: $thirdColor;
$colorLoginHoverButtonBackground: $firstColor;
$colorLoginHoverButtonBorder: $firstColor;
$colorLoginHoverButtonText: $thirdColor;

//5. Investment Payment
$colorInvestmentPaymentHeader: $thirdColor;
$colorInvestmentPaymentTitle: $thirdColor;
$colorInvestmentPaymentBackground: $fourthColor;

$colorInvestmentPaymentAreaBackground: $thirdColor;
$colorInvestmentPaymentAreaHeader: $secondColor;
$colorInvestmentPaymentAreaText: $secondColor;
$colorInvestmentPaymentAreaSubText: $secondColor;

$colorInvestmentPaymentInputBackground: $thirdColor;
$colorInvestmentPaymentInputBorder: $ninthColor;
$colorInvestmentPaymentInputText: $secondColor;
$colorInvestmentPaymentHoverInputBorder: $sixthColor;
$colorInvestmentPaymentPlaceholderText: $secondColor;

$colorInvestmentPaymentCoefficientsButtonBackground: $fourthColor;
$colorInvestmentPaymentCoefficientsButtonBorder: $thirdColor;
$colorInvestmentPaymentCoefficientsButtonText: $thirdColor;
$colorInvestmentPaymentCoefficientsHoverButtonBackground: $fourthColor;
$colorInvestmentPaymentCoefficientsHoverButtonBorder: $fourthColor;
$colorInvestmentPaymentCoefficientsHoverButtonText: $thirdColor;

$colorInvestmentPaymentInfoLink: $thirdColor;
$colorInvestmentPaymentInfoHoverLink: $thirdColor;
$colorInvestmentPaymentInfoLabel: $thirdColor;

$colorInvestmentPaymentCancelButtonBackground: $thirdColor;
$colorInvestmentPaymentCancelButtonBorder: $firstColor;
$colorInvestmentPaymentCancelButtonText: $firstColor;
$colorInvestmentPaymentCancelHoverButtonBackground: $thirdColor;
$colorInvestmentPaymentCancelHoverButtonBorder: $firstColor;
$colorInvestmentPaymentCancelHoverButtonText: $firstColor;

$colorInvestmentPaymentContinueButtonBackground: $fourteenthColor;
$colorInvestmentPaymentContinueButtonBorder: $fourteenthColor;
$colorInvestmentPaymentContinueButtonText: $thirdColor;
$colorInvestmentPaymentContinueHoverButtonBackground: $fourteenthColor;
$colorInvestmentPaymentContinueHoverButtonBorder: $fourteenthColor;
$colorInvestmentPaymentContinueHoverButtonText: $thirdColor;

////5.1 Investment Payment Steps
$colorInvestmentPaymentLink: $firstColor;
$colorInvestmentPaymentText: $secondColor;

////5.2 Investment Payment Info
$colorInvestmentPaymentInfoLinear1: $fourthColor;
$colorInvestmentPaymentInfoLinear2: $sixthColor;
$colorInvestmentPaymentInfoTitle: $thirdColor;
$colorInvestmentPaymentInfoText: $thirdColor;
$colorInvestmentPaymentInfoDivider: $eighteenthColor;

////5.3 Investment Invested
$colorInvestmentInvestedHeader: $secondColor;

$colorInvestmentInvestedInfoBorder: $fifthColor;
$colorInvestmentInvestedInfoTitle: $secondColor;
$colorInvestmentInvestedInfoText: $secondColor;

$colorInvestmentInvestedCardBackground: $thirteenthColor;
$colorInvestmentInvestedCardTitle: $thirdColor;
$colorInvestmentInvestedCardText: $thirdColor;

$colorInvestmentInvestedInformationBorder: $fifthColor;
$colorInvestmentInvestedInformationHeader: $secondColor;
$colorInvestmentInvestedInformationTitle: $secondColor;
$colorInvestmentInvestedInformationText: $secondColor;

$colorInvestmentInvestedInfoParag: $secondColor;
$colorInvestmentInvestedSocialBlockText: $secondColor;

$colorInvestmentInvestedButtonBackground: $fourteenthColor;
$colorInvestmentInvestedButtonBorder: $fourteenthColor;
$colorInvestmentInvestedButtonText: $firstButtonColorText;
$colorInvestmentInvestedHoverButtonBackground: $fourteenthColor;
$colorInvestmentInvestedHoverButtonBorder: $fourteenthColor;
$colorInvestmentInvestedHoverButtonText: $firstButtonColorText;

//6. Footer
$colorFooterBackground: $fourthColor;
$colorFooterIconBackground: $thirdColor;
$colorFooterInputBorder: $fourthColor;

$colorFooterLinkText: $thirdColor;
$colorFooterEmailText: $thirdColor;
$colorFooterHeaderText: $thirdColor;
$colorCompanyInfoText: $thirdColor;
$colorCompanyInfoLinkText: $thirdColor;

$colorFooterNotifyBackground: $thirdColor;
$colorFooterNotifyBorder: $fourthColor;
$colorFooterHoverNotifyBackground: $thirdColor;
$colorFooterHoverNotifyBorder: $fourthColor;
$colorFooterHoverNotifyBoxShadow: rgba(109, 151, 236, 0.2);

$colorFooterButtonBackground: $thirdColor;
$colorFooterButtonBorder: $fourthColor;
$colorFooterButtonText: $fourthColor;
$colorFooterFocusButtonBackground: $thirdColor;
$colorFooterFocusButtonBorder: $fourthColor;
$colorFooterFocusButtonText: $fourthColor;

//7. Header
$colorHeaderBackground: $thirdColor;
$colorHeaderNavbarText: $firstColor;
$colorHeaderNavbarBorder: $firstColor;
$colorHeaderUserNameText: $firstColor;

$colorHeaderDropdownText: $ninthBlackColor;
$colorHeaderDropdownHoverText: $eighthColor;

$colorHeaderDropdownFocusText: $ninthBlackColor;
$colorHeaderDropdownFocusBackground: $firstBlackColor;

$colorHeaderAvatarBackground: $firstColor;
$colorHeaderAvatarText: $thirdColor;
$colorHeaderAvatarDropdownBoxShadow: $fifthBlackColor;
$colorHeaderAvatarDropdownBackground: $thirdColor;
$colorHeaderAvatarDropdownBorder: $eighteenthColor;
$colorHeaderAvatarDropdownText: $firstColor;

$colorHeaderLoginButtonBackground: $firstButtonInvertedColorBackground;
$colorHeaderLoginButtonBorder: $firstButtonInvertedColorBorder;
$colorHeaderLoginButtonText: $firstButtonInvertedColorText;
$colorHeaderHoverLoginButtonBackground: $firstButtonInvertedColorBackground;
$colorHeaderHoverLoginButtonBorder: $firstButtonInvertedColorBorder;
$colorHeaderHoverLoginButtonText: $firstButtonInvertedColorText;

$colorHeaderCreateProjectButtonBackground: $firstButtonColorBackground;
$colorHeaderCreateProjectButtonBorder: $firstButtonColorBorder;
$colorHeaderCreateProjectButtonText: $firstButtonColorText;
$colorHeaderHoverCreateProjectButtonBackground: $firstButtonColorBackground;
$colorHeaderHoverCreateProjectButtonBorder: $firstButtonColorBorder;
$colorHeaderHoverCreateProjectButtonText: $firstButtonColorText;

$colorHeaderBkyButtonBackground: $fourthColor;
$colorHeaderBkyButtonBorder: $fourthColor;
$colorHeaderBkyButtonText: $thirdColor;

////7.1 Header Mobile
$colorHeaderMobileNavbarBackground: $eighthColor;
$colorHeaderMobileNavbarBorder: $ninthColor;
$colorHeaderMobileNavbarText: $firstColor;

$colorHeaderMobileFocusNavbarBackground: $eighthColor;
$colorHeaderMobileFocusNavbarBorder: $ninthColor;
$colorHeaderMobileFocusNavbarText: $firstColor;

$colorHeaderMobileButtonBackground: $thirdColor;
$colorHeaderMobileButtonBorder: $ninthColor;
$colorHeaderMobileButtonText: $sixthBlackColor;
$colorHeaderMobileHoverButtonBackground: $thirdColor;
$colorHeaderMobileHoverButtonBorder: $ninthColor;
$colorHeaderMobileHoverButtonText: $sixthBlackColor;

//8. Cookie Consent
$colorCookieConsentBackground: $ninthBlackColor;
$colorCookieConsentText: $thirdColor;
$colorCookieConsentLink: $thirdColor;

$colorCookieConsentButtonBackground: $fifthColor;
$colorCookieConsentButtonBorder: $sixthColor;
$colorCookieConsentButtonText: $thirdColor;
$colorCookieConsentHoverButtonBackground: $fifthColor;
$colorCookieConsentHoverButtonBorder: $fifthColor;
$colorCookieConsentHoverButtonText: $thirdColor;

//9. Empty Page
$colorEmptyPageBackground: $thirdColor;
$colorEmptyPageHeader: $secondColor;
$colorEmptyPageText: $secondColor;

$colorEmptyPageCardBackground: $seventeenthColor;

$colorEmptyPageButtonBackground: $firstColor;
$colorEmptyPageButtonBorder: $firstColor;
$colorEmptyPageButtonText: $thirdColor;
$colorEmptyPageHoverButtonBackground: $firstColor;
$colorEmptyPageHoverButtonBorder: $firstColor;
$colorEmptyPageHoverButtonText: $thirdColor;

//10. Term Modal
$colorTermModalButtonTextBackground: $firstButtonColorBackground;
$colorTermModalButtonTextBorder: $firstButtonColorBorder;
$colorTermModalButtonText: $firstButtonColorText;
$colorTermModalHoverButtonTextBackground: $firstButtonColorBackground;
$colorTermModalHoverButtonTextBorder: $firstButtonColorBorder;
$colorTermModalHoverButtonText: $firstButtonColorText;

//11. Custom Modal
$colorCustomModalHeader: $secondColor;
$colorCustomModalTitle: $secondColor;
$colorCustomModalText: $secondColor;

$colorCustomModalHeaderDivider: $eighteenthColor;
$colorCustomModalFooterDivider: $eighteenthColor;

$colorCustomModalCancelButtonBackground: $firstButtonInvertedColorBackground;
$colorCustomModalCancelButtonBorder: $firstButtonInvertedColorBorder;
$colorCustomModalCancelButtonText: $firstButtonInvertedColorText;

$colorCustomModalContinueButtonBackground: $firstButtonColorBackground;
$colorCustomModalContinueButtonBorder: $firstButtonColorBorder;
$colorCustomModalContinueButtonText: $firstButtonColorText;
$colorCustomModalContinueHoverButtonBackground: $firstButtonColorBackground;
$colorCustomModalContinueHoverButtonBorder: $firstButtonColorBorder;
$colorCustomModalContinueHoverButtonText: $firstButtonColorText;

//12. Input-Checkbox
$colorInputText: $secondColor;
$colorInputBorder: $ninthColor;
$colorHoverInputBorder: $fifthColor;
$colorFocusInputBorder: $fifthColor;
$colorFocusInputBoxShadow: rgba(130, 57, 141, 0.2);

$colorCheckboxBorder: $ninthColor;
$colorHoverCheckboxBorder: $fifthColor;
$colorFocusCheckboxBorder: $fifthColor;

$colorCheckboxCheckedBackground: $fifthColor;
$colorCheckboxCheckedBorder: $fifthColor;

//13. Steps
$colorStepsActiveItemTitle: $secondColor;
$colorStepsItemTitle: $fourthBlackColor;

$colorStepsTail: $eighteenthColor;
$colorStepsAfterTail: $firstColor;

$colorStepsIconBackground: $firstColor;
$colorStepsIconBorder: $firstColor;
$colorStepsIconText: $thirdColor;

$colorStepsFinishIconBackground: $thirdColor;
$colorStepsFinishIconBorder: $firstColor;
$colorStepsFinishIconText: $sixthColor;

$colorStepsHoverIconBackground: $thirdColor;
$colorStepsHoverIconBorder: $sixthColor;
$colorStepsHoverIconText: $sixthColor;
$colorStepsHoverIconTitle: $sixthColor;

//14. Select Option
$colorSelect: $secondColor;
$colorSelectBorder: $ninthColor;
$colorSelectArrow: $secondColor;
$colorSelectDropdown: $secondColor;

$colorHoverSelectBorder: $sixthColor;
$colorFocusSelectBorder: $sixthColor;
$colorFocusSelectBoxShadow: rgba(130, 57, 141, 0.2);

$colorActiveSelectItemBackground: $ninthColor;
$colorHoverSelectItemBackground: $ninthColor;
$colorChangeSelectItemBackground: $eighthColor;

//15. Form Control
$colorFormControl: $secondColor;
$colorFormControlBorder: rgba(130, 57, 141, 0.2);
$colorFormControlBoxShadow: rgba(130, 57, 141, 0.2);

//16. Pagination
$colorPaginationItemBackground: $thirdColor;
$colorPaginationItemBorder: $eighteenthColor;
$colorPaginationItemText: $secondColor;
$colorPaginationHoverItemBackground: $thirdColor;
$colorPaginationHoverItemBorder: $sixthColor;
$colorPaginationHoverItemText: $secondColor;

$colorPaginationActiveItemBackground: $thirdColor;
$colorPaginationActiveItemBorder: $sixthColor;
$colorPaginationActiveItemText: $secondColor;
$colorPaginationHoverActiveItemBackground: $thirdColor;
$colorPaginationHoverActiveItemBorder: $sixthColor;
$colorPaginationHoverActiveItemText: $secondColor;

//17. Progress Bar
$colorProgressBarBoxShadow: $firstBlackColor;
$colorActiveProgressBarLeft: $firstColor;
$colorActiveProgressBarRight: $fifthColor;

$colorFinishedProgressBarLeft: $firstColor;
$colorFinishedProgressBarRight: $fifthColor;

$colorProgressBarCircle: $fifthColor;

//18. Static Page
$colorStaticPageHeader: $colorBlack;
$colorStaticPageTitle: $colorBlack;
$colorStaticPageText: $colorBlack;
$colorStaticPageLink: $seventhBlackColor;

$logoWidth: 100%;
$logoMaxWidth: 161px;
$logoHeight: 80px;
$logoMaxHeight: 80px;

// iphone-------
$small: 'only screen and (min-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)';
$medium: '(min-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)';
$large: '(min-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)';
// iphone-------

.mt-20px {
  margin-top: 20px;
}

.p-45px {
  padding: 45px;
}

.font-25px {
  font-size: 25px;
}

.text-align-end {
  text-align: end;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  vertical-align: top;
}

// Input
.ant-input {
  color: $colorInputText;
  border-color: $colorInputBorder;

  &:hover {
    color: $colorInputText !important;
    border-color: $colorHoverInputBorder !important;
  }
  &:focus {
    color: $colorInputText !important;
    border-color: $colorFocusInputBorder !important;
    box-shadow: 0 0 0 2px $colorFocusInputBoxShadow !important;
  }
}

// Checkbox
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: none !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: $colorHoverCheckboxBorder !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $colorCheckboxCheckedBackground !important;
  border-color: $colorCheckboxCheckedBorder !important;
}
.ant-checkbox-inner {
  border-color: $colorCheckboxBorder !important;
}
.ant-checkbox-checked::after {
  border-color: $colorCheckboxCheckedBorder !important;
}

// Button and Link Text-Decoration
.ant-btn {
  &:hover {
    text-decoration: none !important;
  }
  a {
    &:hover {
      text-decoration: none !important;
    }
  }
}
a {
  &:hover {
    text-decoration: none !important;
  }
}

// Steps Icon and Text -- Project Create, Payment
.ant-steps-item-title {
  color: $colorStepsItemTitle !important;
  font-family: $fontFamily1SemiBold;
  font-size: 15px;
}

.ant-steps-item-wait .ant-steps-item-container {
  &:hover {
    .ant-steps-item-icon {
      background: $colorStepsHoverIconBackground !important;
      border-color: $colorStepsHoverIconBorder !important;
      .ant-steps-icon {
        color: $colorStepsHoverIconText !important;
      }
    }
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: $colorStepsHoverIconTitle !important;
      }
    }
  }
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-icon {
      background: $colorStepsFinishIconBackground !important;
      border-color: $colorStepsFinishIconBorder !important;
      .ant-steps-icon {
        color: $colorStepsFinishIconText !important;
      }
    }
    .ant-steps-item-content {
      .ant-steps-item-title::after {
        background-color: $colorStepsAfterTail !important;
      }
    }
    .ant-steps-item-tail {
      padding: 0px !important;

      &:after {
        background-color: $colorStepsAfterTail !important;
        height: 8.5px !important;
      }
    }
    &:hover {
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: $colorStepsHoverIconTitle !important;
        }
      }
    }
  }
}

.ant-steps-item-process {
  .ant-steps-item-container {
    .ant-steps-item-content {
      .ant-steps-item-title::after {
        background-color: $colorStepsTail !important;
      }
      .ant-steps-item-title {
        font-family: $fontFamily1SemiBold;
        font-size: 15px;
        color: $colorStepsActiveItemTitle !important;
      }
    }
    .ant-steps-item-icon {
      background: $colorStepsIconBackground !important;
      border-color: $colorStepsIconBorder !important;

      @media screen and (max-width: 991px) {
        margin-left: 0px !important;
      }
      .ant-steps-icon {
        color: $colorStepsIconText !important;
      }
    }
  }
}

// Form Control -- Footer
.form-control {
  color: $colorFormControl !important;
}

.form-control:focus {
  border-color: $colorFormControlBorder !important;
  box-shadow: 0 0 0 0.1rem $colorFormControlBoxShadow !important;
}

//Selection -- Project Sidebar
.ant-select-selection {
  font-family: $fontFamily1;
  border: 1px solid $colorSelectBorder !important;
}
.ant-select {
  font-family: $fontFamily1;
  color: $colorSelect !important;
}
.ant-select-arrow-icon {
  color: $colorSelectArrow !important;
}
.ant-select-dropdown-menu-item {
  font-family: $fontFamily1;
  color: $colorSelectDropdown !important;
}

.ant-select-selection:hover {
  border-color: $colorHoverSelectBorder !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: $colorActiveSelectItemBackground !important;
}
.ant-select-focused .ant-select-selection {
  box-shadow: 0 0 0 2px $colorFocusSelectBoxShadow !important;
  border: 1px solid $colorFocusSelectBorder !important;
}
.ant-select-open .ant-select-selection {
  box-shadow: 0 0 0 2px $colorFocusSelectBoxShadow !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: $colorHoverSelectItemBackground !important;
}
.ant-select-dropdown-menu-item-selected {
  background-color: $colorChangeSelectItemBackground !important;
}

// Pagination -- Portfolio
.ant-pagination-item {
  background: $colorPaginationItemBackground !important;
  border-color: $colorPaginationItemBorder !important;
  a {
    color: $colorPaginationItemText !important;
  }
  &:hover {
    background: $colorPaginationHoverItemBackground !important;
    border-color: $colorPaginationHoverItemBorder !important;
    a {
      color: $colorPaginationHoverItemText !important;
    }
  }
}

.ant-pagination-item-active {
  background: $colorPaginationActiveItemBackground !important;
  border-color: $colorPaginationActiveItemBorder !important;
  a {
    color: $colorPaginationActiveItemText !important;
  }
  &:hover {
    background: $colorPaginationHoverActiveItemBackground !important;
    border-color: $colorPaginationHoverActiveItemBorder !important;
    a {
      color: $colorPaginationHoverActiveItemText !important;
    }
  }
}

.progress {
  border-radius: 6.5px !important ;
}

.progress-bar {
  background-color: transparent !important;
  background-image: linear-gradient(
    90deg,
    $colorActiveProgressBarLeft 0%,
    $colorActiveProgressBarRight 100%
  );
  border-radius: 6.5px;
}
.progress-bar-left {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.progress-bar-right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.progress-bar.bg-success {
  background-color: transparent !important;
}

.ant-progress-circle-path {
  stroke: $colorProgressBarCircle !important;
}

.MuiInputBase-root {
  height: 32px;
  padding: 0px 11px;
  border-radius: 4px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 4px;
}

.MuiInputBase-input {
  height: 32px !important;
  padding: 0px !important;
  background-color: white !important;
  color: $colorInputText !important;
}

.MuiOutlinedInput-root {
  color: $colorInputText !important;
  background-color: white !important;
  border-color: $colorInputBorder !important;
  font-family: $fontFamily1 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;

  &:hover {
    color: $colorInputText !important;
    border-color: $colorHoverInputBorder !important;
  }
  &:focus {
    color: $colorInputText !important;
    border-color: $colorFocusInputBorder !important;
    box-shadow: 0 0 0 2px $colorFocusInputBoxShadow !important;
  }
}

.MuiSvgIcon-root {
  height: 0.8em !important;
}

.css-ihdtdm {
  border: none !important;
}

.MuiFormControl-root {
  width: 100%;
  color: $colorInputText !important;
  border: 1px solid !important;
  border-radius: 4px !important;
  border-color: $colorInputBorder !important;
  &:hover {
    color: $colorInputText !important;
    border-color: $colorHoverInputBorder !important;
  }
  &:focus {
    color: $colorInputText !important;
    border-color: $colorFocusInputBorder !important;
    box-shadow: 0 0 0 2px $colorFocusInputBoxShadow !important;
  }
}

.MuiOutlinedInput-root.Mui-disabled {
  background-color: #f5f5f5 !important;
  cursor: not-allowed !important;
}

.MuiOutlinedInput-input.Mui-disabled {
  background-color: #f5f5f5 !important;
  color: $colorInputText !important;
  -webkit-text-fill-color: $colorInputText !important;
  cursor: not-allowed !important;
}
