$fontFamily1: 'SourceSansProRegular';
$fontFamily1Light: 'SourceSansProLight';
$fontFamily1SemiBold: 'SourceSansProSemiBold';
$fontFamily1Bold: 'SourceSansProBold';

$fontFamily2: 'MontserratRegular';
$fontFamily2Light: 'MontserratLight';
$fontFamily2SemiBold: 'MontserratSemiBold';
$fontFamily2Bold: 'MontserratBold';

$colorTundora: #4e4e4e;
$colorDarkGray: #303030;
$colorDivider: #e8e8e8;
$colorAmericanSilver: #cecece;
$colorBoxShadow: #6c757d80;
$transparent: transparent;

$colorWhite: #ffffff;
$colorAlabaster: #f9f9f9;
$colorAlto: #d9d9d9;
$colorStoneWhite: #ffffffc2;

$colorBiscay: #19356d;
$colorForestGreen: #3da52b;
$colorCorn: #dcae04;
$colorRed: #fd0c0c;
$colorOrange: #ef4328;

$colorBlueFlag: #6d97ec;
$colorRedFlag: #d81010;
$colorGreenFlag: #9184e1;
$colorOrangeFlag: #82398d;

$colorBlack: #000;
$firstBlackColor: #0000001a;
$secondBlackColor: #00000033;
$thirdBlackColor: #0000004d;
$fourthBlackColor: #00000066;
$fifthBlackColor: #00000080;
$sixthBlackColor: #00000099;
$seventhBlackColor: #000000b3;
$eighthBlackColor: #000000cc;
$ninthBlackColor: #000000e6;

$colorGigas: #4e3f92;
$colorPortGore: #29295e;
$colorVividViolet: #82398d;
$colorJagger: #480e51;
$colorMineShaft: #303030;
$colorCornFlowerBlue: #e5dffb;
$colorSapphire: #314b99;
$colorPortage: #2e245d;

$firstColor: $colorGigas;
$secondColor: $colorTundora;
$thirdColor: $colorWhite;
$fourthColor: $colorPortGore;
$fifthColor: $colorVividViolet;
$sixthColor: $colorJagger;
$seventhColor: $colorCornFlowerBlue;
$eighthColor: $colorAlabaster;
$ninthColor: $colorAlto;
$tenthColor: $colorBiscay;
$eleventhColor: $colorSapphire;
$twelfthColor: $colorForestGreen;
$thirteenthColor: $colorRed;
$fourteenthColor: $colorPortage;
$fifteenthColor: $colorCorn;
$sixteenthColor: $colorDarkGray;
$seventeenthColor: $colorStoneWhite;
$eighteenthColor: $colorDivider;
$nineteenthColor: $colorMineShaft;

$firstButtonColorBackground: $firstColor;
$firstButtonColorBorder: $firstColor;
$firstButtonColorText: $thirdColor;

$firstButtonInvertedColorBackground: $thirdColor;
$firstButtonInvertedColorBorder: $firstColor;
$firstButtonInvertedColorText: $firstColor;

//1. Main Page
$colorMainPageContentDescTitle: $secondColor;
$colorMainPageContentDescSubTitle: $secondColor;

$colorMainPageDescriptionTitle: $secondColor;
$colorMainPageDescription: $secondColor;
$colorMainPageIcons: $secondColor;

$colorMainPageContentPartTitle: $secondColor;
$colorMainPageContentPartSubTitle: $colorAmericanSilver;

$colorMainPageProjectDetailTitle: $secondColor;
$colorMainPageEmptyProjectText: $secondColor;
$colorMainPageMainWrapperBackground: $eighthColor;
$colorMainPageSecondaryWrapperBackground: $thirdColor;

$colorMainPageShowAllButtonBackground: $firstButtonColorBackground;
$colorMainPageShowAllButtonBorder: $firstButtonColorBorder;
$colorMainPageShowAllButtonText: $thirdColor;
$colorMainPageHoverShowAllButtonBackground: $firstButtonColorBackground;
$colorMainPageHoverShowAllButtonBorder: $firstButtonColorBorder;
$colorMainPageHoverShowAllButtonText: $thirdColor;

////1.1 Main Page Slider
$colorSliderFirstTitleText: $thirdColor;
$colorSliderFirstText: $thirdColor;

$colorSliderFirstButtonBackground: $sixthColor;
$colorSliderFirstButtonBorder: $sixthColor;
$colorSliderFirstButtonText: $thirdColor;
$colorSliderHoverFirstButtonBackground: $sixthColor;
$colorSliderHoverFirstButtonBorder: $sixthColor;
$colorSliderHoverFirstButtonText: $thirdColor;

$colorSliderFirstDotBackground: $sixthColor;
$colorSliderActiveFirstDotBackground: $sixthColor;

$colorSliderSecondTitleText: $thirdColor;
$colorSliderSecondText: $thirdColor;

$colorSliderSecondButtonBackground: $sixthColor;
$colorSliderSecondButtonBorder: $sixthColor;
$colorSliderSecondButtonText: $thirdColor;
$colorSliderHoverSecondButtonBackground: $sixthColor;
$colorSliderHoverSecondButtonBorder: $sixthColor;
$colorSliderHoverSecondButtonText: $thirdColor;

$colorSliderSecondDotBackground: $thirdColor;
$colorSliderSecondActiveDotBackground: $thirdColor;

//2. Dashboard
////2.1 Sidebar
$colorDashboardSidebarMenuFromBackground: $fourthColor;
$colorDashboardSidebarMenuToBackground: $fourthColor;
$colorDashboardSidebarDivider: $thirdColor;

$colorDashboardSidebarIconBoxShadow: $seventeenthColor;
$colorDashboardSidebarIcon: $thirdColor;
$colorDashboardSidebarText: $seventeenthColor;

$colorDashboardSidebarActiveIcon: $seventeenthColor;
$colorDashboardSidebarActiveText: $thirdColor;

$colorDashboardSidebarActiveItemFromBackground: $seventeenthColor;
$colorDashboardSidebarActiveItemToBackground: $transparent;

$colorDashboardSidebarMobileButtonBackground: $thirdColor;
$colorDashboardSidebarMobileButtonBorder: $ninthColor;
$colorDashboardSidebarMobileButtonText: $sixthBlackColor;
$colorDashboardSidebarMobileHoverButtonBackground: $thirdColor;
$colorDashboardSidebarMobileHoverButtonBorder: $sixthColor;
$colorDashboardSidebarMobileHoverButtonText: $sixthColor;

////2.2 Welcome
$colorDashboardWelcomeTitle: $firstColor;
$colorDashboardWelcomeDescription: $firstColor;
$colorDashboardWelcomeDivider: $firstColor;

////2.3 Profile
//////2.3.1 Profile Info
$colorDashboardProfileInfoContainerBoxShadow: $secondBlackColor;
$colorDashboardProfileInfoContainerBackground: linear-gradient(180deg, #3e4598 0%, #0f5b9d 100%);
$colorDashboardProfileInfoContainerTitle: $thirdColor;
$colorDashboardProfileInfoContainerText: $thirdColor;

$colorDashboardProfileInfoDisabledInputBackground: $thirdColor;
$colorDashboardProfileInfoDisabledInputText: $thirdColor;

$colorDashboardProfileUpdateButtonBackground: $firstButtonColorBackground;
$colorDashboardProfileUpdateButtonBorder: $firstButtonColorBorder;
$colorDashboardProfileUpdateButtonText: $firstButtonColorText;
$colorDashboardProfileUpdateHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardProfileUpdateHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardProfileUpdateHoverButtonText: $firstButtonColorText;

$colorDashboardProfileInfoButtonBackground: $fifthColor;
$colorDashboardProfileInfoButtonBorder: $fifthColor;
$colorDashboardProfileInfoButtonText: $thirdColor;
$colorDashboardProfileInfoHoverButtonBackground: $fifthColor;
$colorDashboardProfileInfoHoverButtonBorder: $fifthColor;
$colorDashboardProfileInfoHoverButtonText: $thirdColor;

//////2.3.2 Identity
$colorDashboardIdentityVerifiedContainerBackground: $eleventhColor;
$colorDashboardIdentityVerifiedContainerBorder: $eleventhColor;

$colorDashboardIdentityPendingContainerBackground: $eleventhColor;
$colorDashboardIdentityPendingContainerBorder: $eleventhColor;

$colorDashboardIdentityVerifyContainerBackground: $eleventhColor;
$colorDashboardIdentityVerifyContainerBorder: $eleventhColor;

$colorDashboardIdentityContainerBoxShadow: $secondBlackColor;
$colorDashboardIdentityHeader: $thirdColor;
$colorDashboardIdentityTitle: $thirdColor;
$colorDashboardIdentityInputText: $seventhBlackColor;

$colorDashboardIdentityBorder: $thirdColor;
$colorDashboardIdentityLabel: $thirdColor;
$colorDashboardIdentityText: $thirdColor;
$colorDashboardIdentityVerificationErrorText: $thirteenthColor;

$colorDashboardIdentityButtonBackground: $firstButtonColorBackground;
$colorDashboardIdentityButtonBorder: $firstButtonColorBorder;
$colorDashboardIdentityButtonText: $firstButtonColorText;
$colorDashboardIdentityHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardIdentityHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardIdentityHoverButtonText: $firstButtonColorText;

$colorDashboardIdentityVerificationText: $fifthColor;

//////2.3.3 Investment
$colorDashboardInvestmentContainerBoxShadow: $secondBlackColor;
$colorDashboardInvestmentContainerBackground: $seventhColor;

$colorDashboardInvestmentContainerTitle: $tenthColor;
$colorDashboardInvestmentContainerText: $tenthColor;
$colorDashboardInvestmentContainerLink: $fifthColor;
$colorDashboardInvestmentContainerHoverLink: $fifthColor;

$colorDashboardInvestmentContainerDivider: $colorBlack;

$colorDashboardInvestmentContainerButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentContainerButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentContainerButtonText: $firstButtonColorText;
$colorDashboardInvestmentContainerHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentContainerHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentContainerHoverButtonText: $firstButtonColorText;

////////2.3.3.1 Income Statement
$colorDashboardInvestmentIncomeStatementHeader: $nineteenthColor;
$colorDashboardInvestmentIncomeStatementTitle: $nineteenthColor;

$colorDashboardInvestmentIncomeStatementGridBackground: $eighthColor;
$colorDashboardInvestmentIncomeStatementTableBorder: $firstColor;
$colorDashboardInvestmentIncomeStatementTableHeader: $secondColor;
$colorDashboardInvestmentIncomeStatementTableData: $secondColor;

$colorDashboardInvestmentIncomeStatementButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentIncomeStatementButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentIncomeStatementButtonText: $firstButtonColorText;
$colorDashboardInvestmentIncomeStatementHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardInvestmentIncomeStatementHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardInvestmentIncomeStatementHoverButtonText: $firstButtonColorText;

//////2.3.4 ChangePassword
$colorDashboardChangePasswordContainerBoxShadow: $secondBlackColor;
$colorDashboardChangePasswordContainerBackground: $seventhColor;
$colorDashboardChangePasswordTitle: $tenthColor;
$colorDashboardChangePasswordText: $tenthColor;

$colorDashboardChangePasswordButtonBackground: $firstButtonColorBackground;
$colorDashboardChangePasswordButtonBorder: $firstButtonColorBorder;
$colorDashboardChangePasswordButtonText: $thirdColor;
$colorDashboardChangePasswordHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardChangePasswordHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardChangePasswordHoverButtonText: $thirdColor;

//////2.3.5 E-mail Subscription
$colorDashboardEmailSubscriptionBoxShadow: $secondBlackColor;
$colorDashboardEmailSubscriptionBackground: $thirdColor;
$colorDashboardEmailSubscriptionTitle: $fifthColor;
$colorDashboardEmailSubscriptionText: $sixteenthColor;

$colorDashboardEmailSubscriptionButtonBackground: $firstButtonColorBackground;
$colorDashboardEmailSubscriptionButtonBorder: $firstButtonColorBorder;
$colorDashboardEmailSubscriptionButtonText: $firstButtonColorText;
$colorDashboardEmailSubscriptionHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardEmailSubscriptionHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardEmailSubscriptionHoverButtonText: $firstButtonColorText;

//////2.3.6 Legal Info
$colorDashboardLegalInfoContainerBoxShadow: $secondBlackColor;
$colorDashboardLegalInfoContainerBackground: $seventhColor;
$colorDashboardLegalInfoContainerText: $firstColor;
$colorDashboardLegalInfoContainerLink: $firstColor;
$colorDashboardLegalInfoContainerList: $firstColor;

//////2.3.7 Delete User
$colorDashboardDeleteUserContainerBoxShadow: $secondBlackColor;
$colorDashboardDeleteUserContainerBackground: $seventhColor;
$colorDashboardDeleteUserText: $firstColor;
$colorDashboardDeleteUserError: $firstColor;

$colorDashboardDeleteUserButtonBackground: $firstButtonColorBackground;
$colorDashboardDeleteUserButtonBorder: $firstButtonColorBorder;
$colorDashboardDeleteUserButtonText: $firstButtonColorText;
$colorDashboardDeleteUserHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardDeleteUserHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardDeleteUserHoverButtonText: $firstButtonColorText;

//////2.3.8 Support Info
$colorDashboardSupportInfoContainerBoxShadow: $secondBlackColor;
$colorDashboardSupportInfoContainerBackground: $seventhColor;
$colorDashboardSupportInfoText: $firstColor;
$colorDashboardSupportInfoLink: $firstColor;

////2.4 Projects
$colorDashboardProjectsHeader: $sixteenthColor;
$colorDashboardProjectsTitle: $secondColor;
$colorDashboardBoxShadow: $fourthBlackColor;

$colorDashboardProjectsHeaderText: $secondColor;
$colorDashboardProjectsDescriptionText: $secondColor;
$colorDashboardProjectsDescriptionTitle: $secondColor;

$colorDashboardProjectsSucceededText: $twelfthColor;
$colorDashboardProjectsFailedText: $thirteenthColor;
$colorDashboardProjectsProcessingText: $fifteenthColor;

$colorDashboardProjectsRowBackground: $eighthColor;
$colorDashboardProjectsColBackground: $thirdColor;

$colorDashboardProjectsLogoContainerBackground: $firstBlackColor;
$colorDashboardProjectsLogoContainerBackgroundHover: $seventhBlackColor;

$colorDashboardProjectsLogoContainerInspectButtonBorder: $firstColor;
$colorDashboardProjectsLogoContainerInspectButtonBackground: $firstColor;
$colorDashboardProjectsLogoContainerInspectButtonText: $thirdColor;

$colorDashboardProjectsLogoContainerUpdateButtonBorder: $firstColor;
$colorDashboardProjectsLogoContainerUpdateButtonBackground: $firstColor;
$colorDashboardProjectsLogoContainerUpdateButtonText: $thirdColor;

$colorDashboardProjectsLogoContainerTablesButtonBorder: $firstColor;
$colorDashboardProjectsLogoContainerTablesButtonBackground: $thirdColor;
$colorDashboardProjectsLogoContainerTablesButtonText: $firstColor;

//////2.4.1 Change Request
$colorDashboardProjectsChangeRequestTitle: $firstColor;
$colorDashboardProjectsChangeRequestDescription: $eighthBlackColor;

$colorDashboardProjectsChangeRequestButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsChangeRequestButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsChangeRequestButtonText: $firstButtonColorText;
$colorDashboardProjectsChangeRequestHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsChangeRequestHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsChangeRequestHoverButtonText: $firstButtonColorText;

//////2.4.2 Tables
$colorDashboardProjectsTablesContainerBackground: $eighthColor;
$colorDashboardProjectsTablesTitle: $fifthColor;
$colorDashboardProjectsTablesText: $firstColor;
$colorDashboardProjectsTablesSummaryText: $nineteenthColor;

$colorDashboardProjectsTablesDivider: $nineteenthColor;
$colorDashboardProjectsTablesGridBackground: $eighthColor;

//////2.4.3 Updates
$colorDashboardProjectsUpdatesHeader: $firstColor;
$colorDashboardProjectsUpdatesLabel: $secondColor;
$colorDashboardProjectsUpdatesText: $secondColor;

$colorDashboardProjectsUpdateTimelineTimeText: $secondColor;
$colorDashboardProjectsUpdateTimelineDivider: $secondColor;
$colorDashboardProjectsUpdateTimelineText: $secondColor;
$colorDashboardProjectsUpdateManagementText: $secondColor;

$colorDashboardProjectsUpdatesButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsUpdatesButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsUpdatesButtonText: $firstButtonColorText;
$colorDashboardProjectsUpdatesHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardProjectsUpdatesHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardProjectsUpdatesHoverButtonText: $firstButtonColorText;

////2.5 Favourites
$colorDashboardFavoutitesHeader: $sixteenthColor;

$colorDashboardFavouritesTableBackground: $eighthColor;
$colorDashboardFavouritesTableData: $sixteenthColor;
$colorDashboardFavouritesTableHeader: $secondColor;
$colorDashboardFavouritesTableLink: $firstColor;
$colorDashboardFavoutitesIcon: $thirteenthColor;

////2.6 Messages
$colorDashboardMessagesHeaderText: $secondColor;
$colorDashboardMessagesItemText: $sixteenthColor;
$colorDashboardMessagesItemBorder: $firstColor;

$colorDashboardMessagesTabText: $sixteenthColor;
$colorDashboardMessagesTabHover: $firstColor;
$colorDashboardMessagesActiveTabText: $firstColor;
$colorDashboardMessagesActiveTabBorder: $firstColor;

$colorDashboardMessagesButtonBorder: $ninthColor;
$colorDashboardMessagesButtonBackground: $thirdColor;
$colorDashboardMessagesButtonText: $secondColor;
$colorDashboardMessagesHoverButtonBorder: $sixthColor;
$colorDashboardMessagesHoverButtonBackground: $thirdColor;
$colorDashboardMessagesHoverButtonText: $sixthColor;

//////2.6.1 Direct Message
$colorDashboardMessagesDirectMessageHeaderText: $sixteenthColor;
$colorDashboardMessagesDirectMessageHeaderIcon: $sixteenthColor;
$colorDashboardMessagesDirectMessageSenderText: $sixteenthColor;

$colorDashboardMessagesDirectMessageSentBackground: $secondColor;
$colorDashboardMessagesDirectMessageReceivedBackground: $firstColor;
$colorDashboardMessagesDirectMessageCardText: $thirdColor;

$colorDashboardMessagesDirectMessageButtonBackground: $firstButtonColorBackground;
$colorDashboardMessagesDirectMessageButtonBorder: $firstButtonColorBorder;
$colorDashboardMessagesDirectMessageButtonText: $firstButtonColorText;
$colorDashboardMessagesDirectMessageHoverButtonBackground: $firstButtonColorBackground;
$colorDashboardMessagesDirectMessageHoverButtonBorder: $firstButtonColorBorder;
$colorDashboardMessagesDirectMessageHoverButtonText: $firstButtonColorText;

////2.7 Portfolio
$colorDashboardPortfolioHeader: $sixteenthColor;
$colorDashboardPortfolioTitle: $fifthColor;
$colorDashboardPortfolioProjectName: $secondColor;

$colorDashboardPortfolioTableBackground: $eighthColor;
$colorDashboardPortfolioTableHeader: $secondColor;
$colorDashboardPortfolioTableData: $secondColor;

$colorDashboardPortfolioDisableButtonBackground: $ninthColor;
$colorDashboardPortfolioDisableButtonBorder: $ninthColor;
$colorDashboardPortfolioDisableButtonText: $thirdColor;
$colorDashboardPortfolioDisableHoverButtonBackground: $ninthColor;
$colorDashboardPortfolioDisableHoverButtonBorder: $ninthColor;
$colorDashboardPortfolioDisableHoverButtonText: $thirdColor;

//////2.7.1 Investment Summary
$colorDashboardPortfolioCount: $fifthColor;
$colorDashboardPortfolioAmount: $fifthColor;
$colorDashboardPortfolioCountText: $secondColor;
$colorDashboardPortfolioAmountText: $secondColor;

$colorDashboardPortfolioFilterText: $secondColor;

$colorDashboardPortfolioIconBorder: $eighthColor;
$colorDashboardPortfolioIconBackground: $transparent;
$colorDashboardPortfolioIcon: $fifthColor;
$colorDashboardPortfolioFocusIconBorder: $eighthColor;
$colorDashboardPortfolioFocusIconBackground: $transparent;
$colorDashboardPortfolioFocusIcon: $fifthColor;

//////2.7.2 Ongoing Projects
$colorDashboardPortfolioCancelButtonBorder: $transparent;
$colorDashboardPortfolioCancelButtonBackground: $thirteenthColor;
$colorDashboardPortfolioCancelButtonText: $thirdColor;
$colorDashboardPortfolioHoverCancelButtonBorder: $transparent;
$colorDashboardPortfolioHoverCancelButtonBackground: $thirteenthColor;
$colorDashboardPortfolioHoverCancelButtonText: $thirdColor;

//////2.7.3 Fineshed Projects
$colorDashboardPortfolioInspectButtonBorder: $transparent;
$colorDashboardPortfolioInspectButtonBackground: $firstColor;
$colorDashboardPortfolioInspectButtonText: $thirdColor;
$colorDashboardPortfolioHoverInspectButtonBorder: $transparent;
$colorDashboardPortfolioHoverInspectButtonBackground: $firstColor;
$colorDashboardPortfolioHoverInspectButtonText: $thirdColor;

//3. Projects
////3.1 Projects Sidebar
$colorProjectsSidebarHeaderText: $sixthColor;
$colorProjectsSidebarTitleText: $sixthColor;
$colorProjectsSidebarCleanFilterText: $fifthColor;
$colorProjectsSidebarText: $secondColor;

$colorProjectsSidebarOrderArrow: $secondColor;

$colorProjectsSidebarMobileButtonBackground: $thirdColor;
$colorProjectsSidebarMobileButtonBorder: $ninthColor;
$colorProjectsSidebarMobileButtonText: $sixthBlackColor;
$colorProjectsSidebarMobileHoverButtonBackground: $thirdColor;
$colorProjectsSidebarMobileHoverButtonBorder: $sixthColor;
$colorProjectsSidebarMobileHoverButtonText: $sixthColor;

////3.2 Project Card
$colorFlagRemainingTime: $thirdColor;

$colorAddFavoriteTextBorder: $thirdColor;
$colorAddFavoriteTextBackground: $thirdColor;
$colorAddFavoriteText: $fifthColor;

$colorRemoveFavoriteTextBorder: $fifthColor;
$colorRemoveFavoriteTextBackground: $fifthColor;
$colorRemoveFavoriteText: $thirdColor;

$colorCardText: $secondColor;
$colorCardCategoryBackground: $thirdColor;
$colorCardCategory: $fifthColor;

$colorCardSuccessText: $twelfthColor;
$colorCardFailText: $thirteenthColor;

////3.3 Project Detail
$colorProjectDetailHeader: $secondColor;
$colorProjectDetailCategoryText: $fourthColor;
$colorProjectDetailLocationText: $secondColor;
$colorProjectDetailHeaderSummary: $secondColor;

$colorProjectDetailTabBackground: $firstColor;
$colorProjectDetailTabActiveBackground: $firstColor;
$colorProjectDetailTabText: $thirdColor;
$colorProjectDetailTabActiveTabText: $thirdColor;

$colorProjectDetailTabBorder: $firstColor;
$colorProjectDetailTabMobileArrow: $thirdColor;
$colorProjectDetailTabMobileArrowBackground: $firstColor;

$colorProjectDetailWebSiteUrl: $fourthColor;
$colorProjectDetailHashtag: $secondColor;
$colorProjectDetailLabels: $secondColor;
$colorProjectDetailProjectDivider: $eighteenthColor;
$colorProjectDetailAbout: $secondColor;

//////3.3.1 Project Detail Card
$colorProjectDetailCardTitle: $secondColor;
$colorProjectDetailCardText: $secondColor;
$colorProjectDetailCardBackground: $thirdColor;
$colorProjectDetailCardShareText: $secondColor;

$colorProjectDetailCardFavored: $firstColor;
$colorProjectDetailCardUnfavored: $secondColor;

$colorProjectDetailFinishedButtonBackground: $eighthColor;
$colorProjectDetailFinishedButtonText: $twelfthColor;

$colorProjectDetailFailButtonBackground: $eighthColor;
$colorProjectDetailFailButtonText: $thirteenthColor;

$colorProjectDetailDraftButtonBackground: $seventhColor;
$colorProjectDetailDraftButtonText: $thirdColor;

$colorProjectDetailButtonBackground: $firstColor;
$colorProjectDetailButtonText: $thirdColor;

//////3.3.2 Project Details
$colorProjectDetailProjectOwner: $secondColor;
$colorProjectDetailIcon: $secondColor;
$colorProjectDetailSiteUrl: $fourthColor;

$colorProjectDetailTitle: $secondColor;
$colorProjectDetailText: $secondColor;
$colorProjectDetailBoldText: $secondColor;
$colorProjectDetailDivider: $eighteenthColor;

$colorProjectDetailTeamCardBackground: $thirdColor;
$colorProjectDetailTeamCardBorder: $thirdColor;
$colorProjectDetailTeamCardHeader: $secondColor;
$colorProjectDetailTeamCardTitle: $secondColor;
$colorProjectDetailTeamCardText: $secondColor;
$colorProjectDetailTeamCardIcon: $secondColor;

$colorProjectDetailSendMessageButtonBackground: $thirdColor;
$colorProjectDetailSendMessageButtonBorder: $ninthColor;
$colorProjectDetailSendMessageButtonText: $sixthBlackColor;
$colorProjectDetailSendMessageHoverButtonBackground: $thirdColor;
$colorProjectDetailSendMessageHoverButtonBorder: $fourthColor;
$colorProjectDetailSendMessageHoverButtonText: $fourthColor;

//////3.3.3 Project Documents
$colorProjectDetailDocumentTitleText: $secondColor;
$colorProjectDetailDocumentsIcon: $secondColor;
$colorProjectDetailDocumentsIconText: $fifthColor;

//////3.3.4 Project Faq
$colorProjectDetailFaqHeaderBackground: $eighthColor;
$colorProjectDetailFaqHeaderText: $secondColor;
$colorProjectDetailFaqHeaderBorder: $ninthColor;

$colorProjectDetailFaqContentBackground: $eighthColor;
$colorProjectDetailFaqContentText: $secondColor;

//////3.3.5 Project Updates
$colorProjectDetailUpdateText: $secondColor;
$colorProjectDetailUpdateDivider: $fifthColor;

$colorProjectDetailUpdateTimelineTimeText: $secondColor;
$colorProjectDetailUpdateTimelineText: $secondColor;
$colorProjectDetailUpdateTimelineDivider: $secondColor;

//////3.3.6 Project Comment
$colorProjectDetailCommentNoDiscussion: $firstColor;

$colorProjectDetailCommentExplain: $thirteenthColor;
$colorProjectDetailCommentDivider: $eighteenthColor;

$colorProjectDetailCommentCommenter: $secondColor;
$colorProjectDetailCommentTime: $secondColor;
$colorProjectDetailCommentComment: $secondColor;
$colorProjectDetailCommentCount: $sixthColor;

$colorProjectDetailCommentButtonBackground: $firstButtonColorBackground;
$colorProjectDetailCommentButtonBorder: $firstButtonColorBorder;
$colorProjectDetailCommentButtonText: $firstButtonColorText;
$colorProjectDetailCommentHoverButtonBackground: $firstButtonColorBackground;
$colorProjectDetailCommentHoverButtonBorder: $firstButtonColorBorder;
$colorProjectDetailCommentHoverButtonText: $firstButtonColorText;

$colorProjectDetailCommentAnswerButtonBackground: $thirdColor;
$colorProjectDetailCommentAnswerButtonBorder: $fourthColor;
$colorProjectDetailCommentAnswerButtonText: $fourthColor;
$colorProjectDetailCommentAnswerHoverButtonBackground: $thirdColor;
$colorProjectDetailCommentAnswerHoverButtonBorder: $fourthColor;
$colorProjectDetailCommentAnswerHoverButtonText: $fourthColor;

//////3.3.7 Project Images
$colorProjectDetailImagesLink: $sixthColor;

////3.4 Project Create
$colorProjectCreateFormBackground: $eighthColor;
$colorProjectCreateFormReadonlyBackground: $thirdColor;

$colorProjectCreateFormHeader: $secondColor;
$colorProjectCreateFormTitle: $secondColor;
$colorProjectCreateFormLabel: $secondColor;
$colorProjectCreateFormText: $secondColor;
$colorProjectCreateLink: $firstColor;

$colorProjectCreateTextAreaBorder: $ninthColor;
$colorProjectCreateTextAreaText: $eighthBlackColor;

$colorProjectCreateImagesLink: $sixthColor;
$colorProjectCreateImagesTrashIcon: $thirteenthColor;

$colorUploaderButtonBackground: $firstColor;
$colorUploaderButtonBorder: $firstColor;
$colorUploaderButtonText: $firstButtonColorText;
$colorUploaderHoverButtonBackground: $firstColor;
$colorUploaderHoverButtonBorder: $firstColor;
$colorUploaderHoverButtonText: $firstButtonColorText;

$colorProjectCreateEditButtonBackground: $transparent;
$colorProjectCreateEditButtonBorder: $transparent;
$colorProjectCreateEditButtonText: $sixthBlackColor;
$colorProjectCreateHoverEditButtonBackground: $transparent;
$colorProjectCreateHoverEditButtonBorder: $transparent;
$colorProjectCreateHoverEditButtonText: $sixthColor;

$colorProjectCreateButtonBackground: $firstButtonColorBackground;
$colorProjectCreateButtonBorder: $firstButtonColorBorder;
$colorProjectCreateButtonText: $thirdColor;
$colorProjectCreateHoverButtonBackground: $firstButtonColorBackground;
$colorProjectCreateHoverButtonBorder: $firstButtonColorBorder;
$colorProjectCreateHoverButtonText: $thirdColor;

$colorProjectCreateDisableAddButtonBackground: $firstButtonColorBackground;
$colorProjectCreateDisableAddButtonBorder: $firstButtonColorBorder;
$colorProjectCreateDisableAddButtonText: $thirdColor;

$colorProjectCreateAddButtonBackground: $firstButtonColorBackground;
$colorProjectCreateAddButtonBorder: $firstButtonColorBorder;
$colorProjectCreateAddButtonText: $firstButtonColorText;

$colorProjectCreateSubmitButtonBackground: $fourteenthColor;

//4. Login
$colorLoginBackground: $thirdColor;
$colorLoginCardBackground: $thirdColor;

$colorLoginHeader: $secondColor;
$colorLoginSubHeader: $secondColor;

$colorLoginText: $secondColor;
$colorLoginLink: $firstColor;

$colorLoginForgetPassword: $firstColor;
$colorLoginSignupCheckLabel: $secondColor;
$colorLoginSignupLink: $fifthColor;

$colorLoginButtonBackground: $firstColor;
$colorLoginButtonBorder: $firstColor;
$colorLoginButtonText: $thirdColor;
$colorLoginHoverButtonBackground: $firstColor;
$colorLoginHoverButtonBorder: $firstColor;
$colorLoginHoverButtonText: $thirdColor;

//5. Investment Payment
$colorInvestmentPaymentHeader: $thirdColor;
$colorInvestmentPaymentTitle: $thirdColor;
$colorInvestmentPaymentBackground: $fourthColor;

$colorInvestmentPaymentAreaBackground: $thirdColor;
$colorInvestmentPaymentAreaHeader: $secondColor;
$colorInvestmentPaymentAreaText: $secondColor;
$colorInvestmentPaymentAreaSubText: $secondColor;

$colorInvestmentPaymentInputBackground: $thirdColor;
$colorInvestmentPaymentInputBorder: $ninthColor;
$colorInvestmentPaymentInputText: $secondColor;
$colorInvestmentPaymentHoverInputBorder: $sixthColor;
$colorInvestmentPaymentPlaceholderText: $secondColor;

$colorInvestmentPaymentCoefficientsButtonBackground: $fourthColor;
$colorInvestmentPaymentCoefficientsButtonBorder: $thirdColor;
$colorInvestmentPaymentCoefficientsButtonText: $thirdColor;
$colorInvestmentPaymentCoefficientsHoverButtonBackground: $fourthColor;
$colorInvestmentPaymentCoefficientsHoverButtonBorder: $fourthColor;
$colorInvestmentPaymentCoefficientsHoverButtonText: $thirdColor;

$colorInvestmentPaymentInfoLink: $thirdColor;
$colorInvestmentPaymentInfoHoverLink: $thirdColor;
$colorInvestmentPaymentInfoLabel: $thirdColor;

$colorInvestmentPaymentCancelButtonBackground: $thirdColor;
$colorInvestmentPaymentCancelButtonBorder: $firstColor;
$colorInvestmentPaymentCancelButtonText: $firstColor;
$colorInvestmentPaymentCancelHoverButtonBackground: $thirdColor;
$colorInvestmentPaymentCancelHoverButtonBorder: $firstColor;
$colorInvestmentPaymentCancelHoverButtonText: $firstColor;

$colorInvestmentPaymentContinueButtonBackground: $fourteenthColor;
$colorInvestmentPaymentContinueButtonBorder: $fourteenthColor;
$colorInvestmentPaymentContinueButtonText: $thirdColor;
$colorInvestmentPaymentContinueHoverButtonBackground: $fourteenthColor;
$colorInvestmentPaymentContinueHoverButtonBorder: $fourteenthColor;
$colorInvestmentPaymentContinueHoverButtonText: $thirdColor;

////5.1 Investment Payment Steps
$colorInvestmentPaymentLink: $firstColor;
$colorInvestmentPaymentText: $secondColor;

////5.2 Investment Payment Info
$colorInvestmentPaymentInfoLinear1: $fourthColor;
$colorInvestmentPaymentInfoLinear2: $sixthColor;
$colorInvestmentPaymentInfoTitle: $thirdColor;
$colorInvestmentPaymentInfoText: $thirdColor;
$colorInvestmentPaymentInfoDivider: $eighteenthColor;

////5.3 Investment Invested
$colorInvestmentInvestedHeader: $secondColor;

$colorInvestmentInvestedInfoBorder: $fifthColor;
$colorInvestmentInvestedInfoTitle: $secondColor;
$colorInvestmentInvestedInfoText: $secondColor;

$colorInvestmentInvestedCardBackground: $thirteenthColor;
$colorInvestmentInvestedCardTitle: $thirdColor;
$colorInvestmentInvestedCardText: $thirdColor;

$colorInvestmentInvestedInformationBorder: $fifthColor;
$colorInvestmentInvestedInformationHeader: $secondColor;
$colorInvestmentInvestedInformationTitle: $secondColor;
$colorInvestmentInvestedInformationText: $secondColor;

$colorInvestmentInvestedInfoParag: $secondColor;
$colorInvestmentInvestedSocialBlockText: $secondColor;

$colorInvestmentInvestedButtonBackground: $fourteenthColor;
$colorInvestmentInvestedButtonBorder: $fourteenthColor;
$colorInvestmentInvestedButtonText: $firstButtonColorText;
$colorInvestmentInvestedHoverButtonBackground: $fourteenthColor;
$colorInvestmentInvestedHoverButtonBorder: $fourteenthColor;
$colorInvestmentInvestedHoverButtonText: $firstButtonColorText;

//6. Footer
$colorFooterBackground: $fourthColor;
$colorFooterIconBackground: $thirdColor;
$colorFooterInputBorder: $fourthColor;

$colorFooterLinkText: $thirdColor;
$colorFooterEmailText: $thirdColor;
$colorFooterHeaderText: $thirdColor;
$colorCompanyInfoText: $thirdColor;
$colorCompanyInfoLinkText: $thirdColor;

$colorFooterNotifyBackground: $thirdColor;
$colorFooterNotifyBorder: $fourthColor;
$colorFooterHoverNotifyBackground: $thirdColor;
$colorFooterHoverNotifyBorder: $fourthColor;
$colorFooterHoverNotifyBoxShadow: rgba(109, 151, 236, 0.2);

$colorFooterButtonBackground: $thirdColor;
$colorFooterButtonBorder: $fourthColor;
$colorFooterButtonText: $fourthColor;
$colorFooterFocusButtonBackground: $thirdColor;
$colorFooterFocusButtonBorder: $fourthColor;
$colorFooterFocusButtonText: $fourthColor;

//7. Header
$colorHeaderBackground: $thirdColor;
$colorHeaderNavbarText: $firstColor;
$colorHeaderNavbarBorder: $firstColor;
$colorHeaderUserNameText: $firstColor;

$colorHeaderDropdownText: $ninthBlackColor;
$colorHeaderDropdownHoverText: $eighthColor;

$colorHeaderDropdownFocusText: $ninthBlackColor;
$colorHeaderDropdownFocusBackground: $firstBlackColor;

$colorHeaderAvatarBackground: $firstColor;
$colorHeaderAvatarText: $thirdColor;
$colorHeaderAvatarDropdownBoxShadow: $fifthBlackColor;
$colorHeaderAvatarDropdownBackground: $thirdColor;
$colorHeaderAvatarDropdownBorder: $eighteenthColor;
$colorHeaderAvatarDropdownText: $firstColor;

$colorHeaderLoginButtonBackground: $firstButtonInvertedColorBackground;
$colorHeaderLoginButtonBorder: $firstButtonInvertedColorBorder;
$colorHeaderLoginButtonText: $firstButtonInvertedColorText;
$colorHeaderHoverLoginButtonBackground: $firstButtonInvertedColorBackground;
$colorHeaderHoverLoginButtonBorder: $firstButtonInvertedColorBorder;
$colorHeaderHoverLoginButtonText: $firstButtonInvertedColorText;

$colorHeaderCreateProjectButtonBackground: $firstButtonColorBackground;
$colorHeaderCreateProjectButtonBorder: $firstButtonColorBorder;
$colorHeaderCreateProjectButtonText: $firstButtonColorText;
$colorHeaderHoverCreateProjectButtonBackground: $firstButtonColorBackground;
$colorHeaderHoverCreateProjectButtonBorder: $firstButtonColorBorder;
$colorHeaderHoverCreateProjectButtonText: $firstButtonColorText;

$colorHeaderBkyButtonBackground: $fourthColor;
$colorHeaderBkyButtonBorder: $fourthColor;
$colorHeaderBkyButtonText: $thirdColor;

////7.1 Header Mobile
$colorHeaderMobileNavbarBackground: $eighthColor;
$colorHeaderMobileNavbarBorder: $ninthColor;
$colorHeaderMobileNavbarText: $firstColor;

$colorHeaderMobileFocusNavbarBackground: $eighthColor;
$colorHeaderMobileFocusNavbarBorder: $ninthColor;
$colorHeaderMobileFocusNavbarText: $firstColor;

$colorHeaderMobileButtonBackground: $thirdColor;
$colorHeaderMobileButtonBorder: $ninthColor;
$colorHeaderMobileButtonText: $sixthBlackColor;
$colorHeaderMobileHoverButtonBackground: $thirdColor;
$colorHeaderMobileHoverButtonBorder: $ninthColor;
$colorHeaderMobileHoverButtonText: $sixthBlackColor;

//8. Cookie Consent
$colorCookieConsentBackground: $ninthBlackColor;
$colorCookieConsentText: $thirdColor;
$colorCookieConsentLink: $thirdColor;

$colorCookieConsentButtonBackground: $fifthColor;
$colorCookieConsentButtonBorder: $sixthColor;
$colorCookieConsentButtonText: $thirdColor;
$colorCookieConsentHoverButtonBackground: $fifthColor;
$colorCookieConsentHoverButtonBorder: $fifthColor;
$colorCookieConsentHoverButtonText: $thirdColor;

//9. Empty Page
$colorEmptyPageBackground: $thirdColor;
$colorEmptyPageHeader: $secondColor;
$colorEmptyPageText: $secondColor;

$colorEmptyPageCardBackground: $seventeenthColor;

$colorEmptyPageButtonBackground: $firstColor;
$colorEmptyPageButtonBorder: $firstColor;
$colorEmptyPageButtonText: $thirdColor;
$colorEmptyPageHoverButtonBackground: $firstColor;
$colorEmptyPageHoverButtonBorder: $firstColor;
$colorEmptyPageHoverButtonText: $thirdColor;

//10. Term Modal
$colorTermModalButtonTextBackground: $firstButtonColorBackground;
$colorTermModalButtonTextBorder: $firstButtonColorBorder;
$colorTermModalButtonText: $firstButtonColorText;
$colorTermModalHoverButtonTextBackground: $firstButtonColorBackground;
$colorTermModalHoverButtonTextBorder: $firstButtonColorBorder;
$colorTermModalHoverButtonText: $firstButtonColorText;

//11. Custom Modal
$colorCustomModalHeader: $secondColor;
$colorCustomModalTitle: $secondColor;
$colorCustomModalText: $secondColor;

$colorCustomModalHeaderDivider: $eighteenthColor;
$colorCustomModalFooterDivider: $eighteenthColor;

$colorCustomModalCancelButtonBackground: $firstButtonInvertedColorBackground;
$colorCustomModalCancelButtonBorder: $firstButtonInvertedColorBorder;
$colorCustomModalCancelButtonText: $firstButtonInvertedColorText;

$colorCustomModalContinueButtonBackground: $firstButtonColorBackground;
$colorCustomModalContinueButtonBorder: $firstButtonColorBorder;
$colorCustomModalContinueButtonText: $firstButtonColorText;
$colorCustomModalContinueHoverButtonBackground: $firstButtonColorBackground;
$colorCustomModalContinueHoverButtonBorder: $firstButtonColorBorder;
$colorCustomModalContinueHoverButtonText: $firstButtonColorText;

//12. Input-Checkbox
$colorInputText: $secondColor;
$colorInputBorder: $ninthColor;
$colorHoverInputBorder: $fifthColor;
$colorFocusInputBorder: $fifthColor;
$colorFocusInputBoxShadow: rgba(130, 57, 141, 0.2);

$colorCheckboxBorder: $ninthColor;
$colorHoverCheckboxBorder: $fifthColor;
$colorFocusCheckboxBorder: $fifthColor;

$colorCheckboxCheckedBackground: $fifthColor;
$colorCheckboxCheckedBorder: $fifthColor;

//13. Steps
$colorStepsActiveItemTitle: $secondColor;
$colorStepsItemTitle: $fourthBlackColor;

$colorStepsTail: $eighteenthColor;
$colorStepsAfterTail: $firstColor;

$colorStepsIconBackground: $firstColor;
$colorStepsIconBorder: $firstColor;
$colorStepsIconText: $thirdColor;

$colorStepsFinishIconBackground: $thirdColor;
$colorStepsFinishIconBorder: $firstColor;
$colorStepsFinishIconText: $sixthColor;

$colorStepsHoverIconBackground: $thirdColor;
$colorStepsHoverIconBorder: $sixthColor;
$colorStepsHoverIconText: $sixthColor;
$colorStepsHoverIconTitle: $sixthColor;

//14. Select Option
$colorSelect: $secondColor;
$colorSelectBorder: $ninthColor;
$colorSelectArrow: $secondColor;
$colorSelectDropdown: $secondColor;

$colorHoverSelectBorder: $sixthColor;
$colorFocusSelectBorder: $sixthColor;
$colorFocusSelectBoxShadow: rgba(130, 57, 141, 0.2);

$colorActiveSelectItemBackground: $ninthColor;
$colorHoverSelectItemBackground: $ninthColor;
$colorChangeSelectItemBackground: $eighthColor;

//15. Form Control
$colorFormControl: $secondColor;
$colorFormControlBorder: rgba(130, 57, 141, 0.2);
$colorFormControlBoxShadow: rgba(130, 57, 141, 0.2);

//16. Pagination
$colorPaginationItemBackground: $thirdColor;
$colorPaginationItemBorder: $eighteenthColor;
$colorPaginationItemText: $secondColor;
$colorPaginationHoverItemBackground: $thirdColor;
$colorPaginationHoverItemBorder: $sixthColor;
$colorPaginationHoverItemText: $secondColor;

$colorPaginationActiveItemBackground: $thirdColor;
$colorPaginationActiveItemBorder: $sixthColor;
$colorPaginationActiveItemText: $secondColor;
$colorPaginationHoverActiveItemBackground: $thirdColor;
$colorPaginationHoverActiveItemBorder: $sixthColor;
$colorPaginationHoverActiveItemText: $secondColor;

//17. Progress Bar
$colorProgressBarBoxShadow: $firstBlackColor;
$colorActiveProgressBarLeft: $firstColor;
$colorActiveProgressBarRight: $fifthColor;

$colorFinishedProgressBarLeft: $firstColor;
$colorFinishedProgressBarRight: $fifthColor;

$colorProgressBarCircle: $fifthColor;

//18. Static Page
$colorStaticPageHeader: $colorBlack;
$colorStaticPageTitle: $colorBlack;
$colorStaticPageText: $colorBlack;
$colorStaticPageLink: $seventhBlackColor;

$logoWidth: 100%;
$logoMaxWidth: 161px;
$logoHeight: 80px;
$logoMaxHeight: 80px;

:local {
  .avatar {
    color: $colorHeaderAvatarText;
    background-color: $colorHeaderAvatarBackground;
    cursor: pointer;

    @media (max-width: 991px) {
      display: none;
    }
  }
}

.nav {
  min-height: 6rem;
  background-color: $colorHeaderBackground !important;

  @media only screen and (max-width: 991px) {
    height: auto;
  }

  .navigation {
    .homeItem {
      font-family: $fontFamily1;

      .navbarBar {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      :global {
        .navbar-nav {
          :local {
            .createProject {
              @media (max-width: 991px) {
                border: none !important;
                box-shadow: none !important;
                outline: none !important;
                width: 100% !important;
                margin: inherit !important;
                padding: 15px 0px !important;
                background: $colorHeaderMobileNavbarBackground !important;
                border-bottom: 1px solid $colorHeaderMobileNavbarBorder !important;
                color: $colorHeaderMobileNavbarText !important;
                letter-spacing: 1.1px !important;
                font-size: 18px !important;

                &:active,
                &:focus {
                  border: solid 1px $colorHeaderMobileFocusNavbarBorder !important;
                  background: $colorHeaderMobileFocusNavbarBackground !important;
                  color: $colorHeaderMobileFocusNavbarText !important;
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 992px) {
      .mobileUsermenu {
        display: none;
      }
    }

    @media only screen and (max-width: 991px) {
      .mobileUsermenu {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        width: 100% !important;
        margin: 0px !important;
        padding: 20px 0px !important;
        background: $colorHeaderMobileNavbarBackground !important;
        border-bottom: 1px solid $colorHeaderMobileNavbarBorder !important;
        color: $colorHeaderMobileNavbarText !important;
        letter-spacing: 1.1px !important;
        font-size: 18px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $fontFamily1SemiBold;

        &:active,
        &:focus {
          border: solid 1px $colorHeaderMobileFocusNavbarBorder !important;
          background: $colorHeaderMobileFocusNavbarBackground !important;
          color: $colorHeaderMobileFocusNavbarText !important;
        }

        div {
          width: 100% !important;
          justify-content: center !important;
          align-items: center !important;
          display: flex;
          line-height: 1;
          padding: 0px !important;
          margin: 0px !important;
          i {
            margin-right: 5px !important;
          }

          a {
            color: $colorHeaderMobileNavbarText !important;
            font-family: $fontFamily1SemiBold;
            width: 100% !important;
            text-align: center;
            display: block;
          }
        }
      }
    }

    @media (max-width: 991px) {
      flex-basis: inherit;
      flex-grow: inherit;
      width: 100%;

      .navbarBar {
        margin-top: 20px;
        align-items: flex-start;
        background: $colorHeaderMobileNavbarBackground !important;
        a {
          &:before {
            display: none;
          }
        }

        :global {
          .nav-link {
            color: $colorHeaderMobileNavbarText !important;
            letter-spacing: 1.1px;
            font-size: 18px !important;
            font-family: $fontFamily1SemiBold;
            text-decoration: inherit !important;
            background-color: inherit !important;
            margin-left: 0px;
            padding: 10px 0px 0px 0px;
            &:active,
            &:focus {
              border: solid 1px $colorHeaderMobileFocusNavbarBorder !important;
              background: $colorHeaderMobileFocusNavbarBackground !important;
              color: $colorHeaderMobileFocusNavbarText !important;
            }

            @media (max-width: 991px) {
              border-bottom: 1px solid $colorHeaderMobileNavbarBorder !important;
              padding: 15px 0px;
              width: 100%;
              text-align: center;
            }
          }

          .dropdown {
            margin-left: 0px !important;
            @media (max-width: 991px) {
              width: 100%;
              text-align: center;
            }

            .dropdown-menu.show {
              display: block;
              border-bottom: 1px solid $colorHeaderMobileNavbarBorder !important;
              padding: 0px 0px 15px !important;
            }

            .dropdown-menu {
              display: none;
              font-family: $fontFamily1SemiBold;

              min-width: inherit;
              padding: inherit;
              margin: inherit;
              text-align: inherit;
              list-style: inherit;
              background-color: inherit;
              border: inherit;
              border-radius: inherit;
              font-size: 22px !important;
              letter-spacing: 1px;
              text-decoration: inherit;

              .dropdown-item {
                font-family: $fontFamily1;
                color: $colorHeaderMobileNavbarText !important;
                text-decoration: inherit !important;
                background-color: inherit !important;
                margin-left: 0px;
                padding: 10px 0px 0px 0px;

                &:active,
                &:focus {
                  font-family: $fontFamily1;
                  border: solid 1px $colorHeaderMobileFocusNavbarBorder;
                  background: $colorHeaderMobileFocusNavbarBackground !important;
                  color: $colorHeaderMobileFocusNavbarText !important;
                }
              }
            }
          }
        }

        .homeButtonSigned {
          margin-top: 20px;
          padding-left: 0px;
        }

        :local {
          .dropdownUsermenu {
            display: none;
            padding-left: 0px;
          }
        }

        .mobileUsermenu {
          display: inherit;
          padding-left: 15px;
          flex-direction: column;
          a,
          div {
            margin-bottom: 8px;
            i {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.mt-3 {
  margin-top: 1rem !important;
}

.homeButtonSign {
  border: solid 1.5px $colorHeaderLoginButtonBorder;
  background-color: $colorHeaderLoginButtonBackground;
  color: $colorHeaderLoginButtonText;
  font-family: $fontFamily1SemiBold;
  font-size: 14px !important;
  width: 170px;
  letter-spacing: 0.41px;
  border-radius: 4px;

  @media (max-width: 991px) {
    background: $colorHeaderMobileNavbarBackground !important;
    color: $colorHeaderMobileNavbarText !important;
    letter-spacing: 0.1px !important;
    width: 134px;
    height: 37px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .d-lg-inline {
      display: none;
    }
  }

  &:hover,
  &:active,
  &:focus {
    border: solid 1.5px $colorHeaderHoverLoginButtonBorder;
    background: $colorHeaderHoverLoginButtonBackground !important;
    color: $colorHeaderHoverLoginButtonText !important;
    box-shadow: 0 0 0 0.1rem $colorBoxShadow !important;

    @media (max-width: 991px) {
      border: solid 1.5px $colorHeaderMobileFocusNavbarBorder !important;
      background: $colorHeaderMobileFocusNavbarBackground !important;
      color: $colorHeaderMobileFocusNavbarText !important;
      box-shadow: none !important;
    }
  }

}

.homeButtonSigned {
  font-family: $fontFamily1;
  font-size: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 991px) {
    justify-content: space-between !important;
    flex-direction: column !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }
}

.createProject {
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: $colorHeaderCreateProjectButtonBackground !important;
  border: solid 1.5px $colorHeaderCreateProjectButtonBorder;
  color: $colorHeaderCreateProjectButtonText !important;
  width: 170px;
  letter-spacing: 1.5px;
  font-family: $fontFamily1SemiBold;

  &:hover,
  &:active,
  &:focus {
    background-color: $colorHeaderHoverCreateProjectButtonBackground !important;
    border-color: $colorHeaderHoverCreateProjectButtonBorder !important;
    color: $colorHeaderHoverCreateProjectButtonText !important;
    box-shadow: 0 0 0 0.1rem $colorBoxShadow !important;
  }
}

:global {
  .ant-avatar-string {
    position: initial !important;
  }

  .anticon > svg {
    vertical-align: baseline !important;
  }

  .nav-link {
    color: $colorHeaderNavbarText !important;
    font-family: $fontFamily1;
    letter-spacing: 1.1px;
    font-size: 22px !important;

    & > a {
      color: $colorHeaderNavbarText !important;
      font-family: $fontFamily1SemiBold;
      font-size: 16px !important;
    }
  }

  .navbar-toggler {
    background-color: $colorHeaderMobileButtonBackground !important;
    border-color: $colorHeaderMobileButtonBorder !important;
    color: $colorHeaderMobileButtonText !important;
    margin-right: 10px !important;
    &:hover,
    &:focus {
      background-color: $colorHeaderMobileHoverButtonBackground !important;
      border-color: $colorHeaderMobileHoverButtonBorder !important;
      color: $colorHeaderMobileHoverButtonText !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .dropdown-menu.show {
    .dropdown-item {
      color: $colorHeaderDropdownText !important;
      &:hover {
        background-color: $colorHeaderDropdownHoverText !important;
      }
      &:active,
      &:focus {
        background-color: $colorHeaderDropdownFocusBackground !important;
        color: $colorHeaderDropdownFocusText !important;
      }
    }
  }
}

.dropdown {
  margin-left: 50px;
  @media only screen and (max-width: 767px) {
    margin-left: 0px;
  }
}

.headerDropdown {
  height: 70px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 $colorHeaderAvatarDropdownBoxShadow !important;
  background-color: $colorHeaderAvatarDropdownBackground !important;
  border: solid 1px $colorHeaderAvatarDropdownBorder !important;
  color: $colorHeaderAvatarDropdownText !important;
  text-align: center;
  padding: 10px 15px 10px 15px;
  letter-spacing: 1.4px;

  font-family: $fontFamily1SemiBold;
  font-size: 16px;

  .logout {
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  div {
    margin-bottom: 5px;
    text-align: start;
  }

  :global {
    .anticon {
      margin-right: 5px !important;
    }
  }

  a {
    color: $colorHeaderAvatarDropdownText;
  }
}

.usernameContainer {
  text-align: center;

  p {
    margin-bottom: 0px !important;
  }
}

.bkyContainer {
  margin-bottom: 0px !important;
}

.username {
  font-size: 16px;
  font-family: $fontFamily1;
  margin-bottom: 5px !important;
  color: $colorHeaderUserNameText;
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    color: $colorHeaderUserNameText;
  }
}

.headerBky {
  border-radius: 7px;
  background-color: $colorHeaderBkyButtonBackground !important;
  border-color: $colorHeaderBkyButtonBorder !important;
  font-size: 13px;
  font-family: $fontFamily1;
  width: 108px;
  height: 24px;
  margin: 0px !important;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: $colorHeaderBkyButtonText;
  }
}

.languageSelector {
  width: 20%;
  @media only screen and (max-width: 575px) {
    margin-left: 0px !important;
    width: 20%;
  }

  @media only screen and (max-width: 991px) {
    padding: 10px !important;
  }

  :global {
    .ant-select-selection {
      border: none;
    }

    .ant-select-selection__rendered {
      margin-right: 45px;
    }
  }
}

.navbar {
  @media (max-width: 991px) {
    position: relative;
    padding-top: 0px;
    margin-top: 0.5rem;
  }
}

.headerNavLink {
  position: relative;
  margin: 0px 40px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    background: $colorHeaderMobileNavbarBackground;
    width: 100%;
    margin: 0px;

    .dropdownUsermenu {
      display: none;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.custom {
    color: $colorHeaderNavbarText;
  }

  &:before {
    position: absolute;
    content: ' ';
    right: -22px;
    width: 22px;
    border-right: 2px solid $colorHeaderNavbarBorder;
    height: 32px !important;
    cursor: default;

    @media only screen and (max-width: 991px) {
      border-right: none;
    }
  }

  &:last-child {
    margin-right: 0px;

    &:before {
      display: none;
    }
  }
}

:global {
  .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
